import React, {useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {setCurrentUserInLocalStorage} from "../CommonFunctions";
import {getUserByEmailWithToken, postAuthenticateUser} from "../../api/apiAuthentication";
import {CircularProgress} from "@material-ui/core";

export const LoginComponent = () => {

    const {handleSubmit, control} = useForm();

    const [connectionErrorMessage, setConnectionErrorMessage] = useState(null);
    const [isConnectionLoading, setIsConnectionLoading] = useState(false);

    const onSubmit = (data) => {
        setIsConnectionLoading(true);
        setConnectionErrorMessage(null);
        // Authenticate user
        postAuthenticateUser(data)
            .then(response => {
                // Case: authentication success
                if(response.status === 200) {
                    response.json()
                        .then(responseData => {
                            // Get user corresponding to authentication token
                            getUserByEmailWithToken(responseData.token)
                                .then(userData => {
                                    if(userData && userData.length > 0) {
                                        // If user found, connect user
                                        connectUser(userData[0], responseData.token);
                                    } else {
                                        handleConnectionError();;
                                    }
                                }).catch(() => handleConnectionError());
                            })
                        .catch(() => handleConnectionError());
                } else {
                    // Case: authentication error
                    if (response.status === 401) {
                        handleConnectionError('Invalid credentials');
                    } else {
                        handleConnectionError();
                    }
                }
            }).catch(() => handleConnectionError());
    }

    const handleConnectionError = (message = 'An error occurred') => {
        setConnectionErrorMessage(message);
        setIsConnectionLoading(false);
    }

    /* Connect user to the app */
    const connectUser = (user, token) => {
        // Set user and token in local storage
        setCurrentUserInLocalStorage(user);
        localStorage.setItem('token', token);
        sessionStorage.setItem('token', token);
        // Redirect user to the app
        window.location = '/';
    }

    const currentYear = new Date().getFullYear();

// if there's no token stored show the message below
    return (
        <div id="main__login">
            <div id="login__left">
                <div id="login__logo"/>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)} id="login__form">
                        <h1>Login</h1>

                        <label htmlFor="email">Email</label>
                        <Controller
                            control={control}
                            name="email"
                            rules={{required: true}}
                            defaultValue=""
                            render={({field}) =>
                                <input type="text" {...field} />}
                        />
                        <br/>
                        <label htmlFor="password">Password</label>
                        <Controller
                            control={control}
                            name="password"
                            rules={{required: true}}
                            defaultValue=""
                            render={({field}) =>
                                <input type="password" id="f-password" {...field} />}
                        />

                        {connectionErrorMessage &&
                            <div className="connection-error-message">Login error: {connectionErrorMessage}</div>
                        }

                        <button>
                            {isConnectionLoading ?
                                <CircularProgress size={18}/> :
                                <span><i className="fas fa-sign-in-alt"/><span> Login</span></span>
                            }
                        </button>
                    </form>

                    {/*<p>*/}
                    {/*    <button onClick={() => window.location = 'register'}>*/}
                    {/*        <span><i className="fas fa-plus"/><span> Register new user</span></span>*/}
                    {/*    </button>*/}
                    {/*</p>*/}

                </div>

                <div id="login__footer">
                    <p>
                        © Handddle {currentYear}<br/>
                        V.{process.env.REACT_APP_VERSION}
                    </p>
                </div>
            </div>
            <div id="login__right"/>
        </div>
    )
}
