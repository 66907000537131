import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {
    formatDataBySystemCode, formatDataListBySystemCode,
    formatPrintersDataById,
    getInfluxdbDataBySystemCode,
    getPrintersStatusData,
    getSingleInfluxdbDataBySystemCode,
} from "../api/apiInfluxdb";
import {
    CHARTS_DATA_RANGE, CHARTS_UNIT_PERIODS, DATA_TIMEOUT_LIMIT,
    getFarmStructurePrintersIds,
    getSmartPrinterStatusFromPrintersStatusData, INFLUXDB_LIST_REQUEST_INTERVAL,
    mergeData, PRINTERS_STATUS_REQUEST_INTERVAL,
    SMART_FARM_DATA_FIELDS
} from "../services/FarmService";
import {getSocketReceivedDataObservable} from "../services/socketEventsService";
import {fetchData} from "../api/useFetch";
import {getInfluxdbDataWindow, getInfluxdbEndDate, getInfluxdbStartDate} from "../services/farm/FarmChartsService";

const LogContext = createContext(undefined);

export const SmartFarmDataProvider = ({ children }) => {

    const [farmStructure, setFarmStructure] = useState(null);

    // Data coming from physical smart farm
    const [smartFarmData, setSmartFarmData] = useState(null);
    const [smartFarmDataHistory, setSmartFarmDataHistory] = useState(null);
    const smartFarmIsReady = smartFarmData !== null && smartFarmDataHistory !== null;
    const [receivingData, setReceivingData] = useState(false);

    // States for printers status
    const [smartPrintersStatusData, setSmartPrintersStatusData] = useState(null);
    const [manualPrintersStatus, setManualPrintersStatus] = useState(null);

    // Selected range for data history
    const [selectedDataRange, setSelectedDataRange] = useState(CHARTS_DATA_RANGE.DAY);
    const [customDataRange, setCustomDataRange] = useState({startDate: null, endDate: null});
    const [unitPeriod, setUnitPeriod] = useState(CHARTS_UNIT_PERIODS.HOUR);

    // Running profile
    const [runningProfileId, setRunningProfileId] = useState(null);

    useEffect(() => {
        const checkReceivingData = () => {
            if(smartFarmData === null) return false;
            const maxTime = Math.max(...Object.values(smartFarmData).map(item => item.time || 0));
            setReceivingData((Date.now() - maxTime) <= DATA_TIMEOUT_LIMIT * 1000);
        };

        checkReceivingData();
        const checkReceivingDataInterval = setInterval(checkReceivingData, 5000);
        return () => clearInterval(checkReceivingDataInterval);
    }, [smartFarmData]);


    /* Information to get to manage data */
    const farmSystemCodes = useMemo(() => {
        let systemCodes = [];
        // Get all smart farm cells system codes
        if(farmStructure !== null && farmStructure.farm_modules) {
            farmStructure.farm_modules.map(module => {
                if(module.farm_cells) {
                    module.farm_cells.map(cell => systemCodes.push(cell.system_code));
                }
            });
        }
        return systemCodes;
    }, [farmStructure]);

    /* Data management */
    useEffect(() => {
        if(farmSystemCodes.length === 0 || smartFarmData !== null) return;

        /* First, get all SF data */
        getSmartFarmData();
        getSmartPrintersStatus();
        getManualPrintersStatus();

        /* Check for printer status regularly */
        const printersStatusInterval = setInterval(() => {
            getSmartPrintersStatus();
            getManualPrintersStatus();
            setSmartFarmData(smartFarmData => mergeData(smartFarmData, {}));
        }, PRINTERS_STATUS_REQUEST_INTERVAL * 1000);

        /* Live received SF data */
        const receivedDataSubscription = getSocketReceivedDataObservable().subscribe({
            next: (data) => {
                setSmartFarmData(smartFarmData => mergeData(smartFarmData, data));
            }
        });

        return () => {
            clearInterval(printersStatusInterval);
            receivedDataSubscription.unsubscribe();
        };
    }, [farmSystemCodes]);


    /* Data history management */
    useEffect(() => {
        if(farmSystemCodes.length === 0) return;
        getSmartFarmDataHistory(selectedDataRange, customDataRange.startDate, customDataRange.endDate)

        const dataHistoryInterval = setInterval(() => {
            getSmartFarmDataHistory(selectedDataRange, customDataRange.startDate, customDataRange.endDate)
        }, INFLUXDB_LIST_REQUEST_INTERVAL * 1000);

        return () => {
            clearInterval(dataHistoryInterval);
        };
    }, [farmSystemCodes, selectedDataRange, customDataRange]);


    /* Auxiliary functions */
    const getSmartFarmData = () => {
        const farmSystemCodesWithBroadcast = [...farmSystemCodes, 'broadcast'];
        getSingleInfluxdbDataBySystemCode(farmSystemCodesWithBroadcast, Object.values(SMART_FARM_DATA_FIELDS), 'now()', 'STM', '-5m')
            .then(data => {setSmartFarmData(formatDataBySystemCode(data));})
            .catch(() => null);
    };

    const getSmartFarmDataHistory = (dataRange, startDate, endDate) => {
        const influxdbUnitPeriod = getInfluxdbDataWindow(dataRange, startDate, endDate);
        const influxdbStartDate = getInfluxdbStartDate(dataRange, startDate);
        const influxdbEndDate = getInfluxdbEndDate(dataRange, endDate);

        getInfluxdbDataBySystemCode(farmSystemCodes,
            [SMART_FARM_DATA_FIELDS.TEMPERATURE, SMART_FARM_DATA_FIELDS.TEMPERATURE_EXT, SMART_FARM_DATA_FIELDS.HUMIDITY, SMART_FARM_DATA_FIELDS.HUMIDITY_EXT],
            influxdbUnitPeriod, influxdbStartDate, influxdbEndDate
        )
            .then(data => {
                setUnitPeriod(influxdbUnitPeriod);
                setSmartFarmDataHistory(formatDataListBySystemCode(data));
            })
            .catch(() => null);
    };

    const getSmartPrintersStatus = () => {
        getPrintersStatusData(getFarmStructurePrintersIds(farmStructure))
            .then(data => {
                setSmartPrintersStatusData(getSmartPrinterStatusFromPrintersStatusData(formatPrintersDataById(data)));
            })
            .catch(() => null);
    }

    const getManualPrintersStatus = () => {
        fetchData('printers/status')
            .then(response => response.json())
            .catch(() => setManualPrintersStatus(null))
            .then(status => setManualPrintersStatus(status))
            .catch(() => setManualPrintersStatus(null));
    }

    const contextValue = useMemo(
        () => ({
            smartFarmData, smartFarmDataHistory, unitPeriod,
            farmStructure, setFarmStructure,
            smartFarmIsReady, receivingData,
            smartPrintersStatusData, manualPrintersStatus,
            selectedDataRange, setSelectedDataRange,
            customDataRange, setCustomDataRange,
            runningProfileId, setRunningProfileId

        }),
        [
            smartFarmData, smartFarmDataHistory, unitPeriod,
            farmStructure, setFarmStructure,
            smartFarmIsReady, receivingData,
            smartPrintersStatusData, manualPrintersStatus,
            selectedDataRange, setSelectedDataRange,
            customDataRange, setCustomDataRange,
            runningProfileId, setRunningProfileId

        ],
    );

    return <LogContext.Provider value={contextValue}>{children}</LogContext.Provider>;
};

export const useSmartFarmData = () => {
    return useContext(LogContext);
};
