import React from "react";
import {Input, InputAdornment} from "@material-ui/core";
import SearchBlack from "../../../images/icons/pic/search_black_24dp";
import {PicCard} from "../Pic.class";
import SetupDisableIcon from "../../../images/icons/pic/1-PreparationFichiers-disable-8.png";
import FabricationDisableIcon from "../../../images/icons/pic/2-fabrication-disable-8.png";
import PostProcessingDisableIcon from "../../../images/icons/pic/3-Post-fabrication-disable-8.png";

export const CardUndefined = (props) => {

    const {handleClickCardItem} = props;

    return (
        <>
            <div className="cards-list">
                <Input className="search-bar" placeholder="Search a step" startAdornment={
                    <InputAdornment position="start">
                        <SearchBlack className="search-icone"/>
                    </InputAdornment>
                }
                />
                {Object.keys(PicCard.DISPLAY_DATA).map((cardKey, index) => {
                    const cardModel = PicCard.DISPLAY_DATA[cardKey];

                    return cardModel.available &&
                        <div className="card-item-container" key={index}>
                            <div className="card-item" onClick={() => handleClickCardItem(cardModel)}>
                                {cardModel.icon}
                                <div>
                                    <h3>{cardModel.name}</h3>
                                    <p>{cardModel.description}</p>
                                </div>
                            </div>
                        </div>
                })}
            </div>
            <div className="step-selection">
                <div className="select-step">
                    <img src={SetupDisableIcon} className="step-icone" alt="step-icon"/>
                    <div>
                        <h3>Setup</h3>
                        <p>Upload 3D files, slicer data, feedstock, ...</p>
                    </div>
                </div>
                <div className="select-step">
                    <img src={FabricationDisableIcon} className="step-icone" alt="step-icon"/>
                    <div>
                        <h3>Machine steps</h3>
                        <p>Machine setup, job duration, ...</p>
                    </div>
                </div>
                <div className="select-step">
                    <img src={PostProcessingDisableIcon} className="step-icone" alt="step-icon"/>
                    <div>
                        <h3>Post-processing</h3>
                        <p>Supports, finitions, quality steps, ...</p>
                    </div>
                </div>
            </div>
        </>
    )
}