import React, {useState} from "react";
import {
    getFormattedDate,
    getConvertedDuration,
    getFormattedDatePicker,
    getFormattedTimePicker,
    getFormattedPrinterName,
    getFormattedRoundedDate,
    getFormattedFullFrDate,
    getFormattedMaterialsName,
    getCurrentTimestamp, isSmartFarmUser
} from "../../CommonFunctions";
import {patchTaskComments, patchStartTask, patchTaskName} from "../../../api/apiGantt";
import {downloadAPIFile} from "../../../api/useFetch";
import VisibilityBlack from "../../../images/icons/pic/visibility_black_24dp";
import VisibilityBlue from "../../../images/icons/pic/visibility_blue_24dp";
import {PieceViewer} from '../../viewer/PieceViewer';
import {DateTimePickerComponent} from "../../common/DateTimePickerComponent";
import {Avatar} from "../../common/Avatar";
import {Controller, useForm} from "react-hook-form";
import {CircularProgress} from "@material-ui/core";
import {Loading} from "../../common/Loading";
import {Error} from "../../common/Error";
import {getEventType} from "../../farm/right-panel/blocks/HistoryEventsBlock";
import {MaterialIcon} from "../../common/icons/MaterialIcon";
import {Alert} from "@material-ui/lab";
import {getTaskProfile} from "../../../services/gantt/GanttService";

export const TaskInformation = (props) => {

    const {task, farmEvents, printFails, successCallback, errorCallback,
        isTaskExternalTask, refetchTask, setAlert, setIsTaskUpdated} = props;

    const {handleSubmit, control} = useForm();

    const [selectedPiece, setSelectedPiece] = useState();

    const handleEditComment = async (e) => {
        await patchTaskComments(task.id, e.target.value)
            .then(() => {
                successCallback('Comments has been updated.');
            })
            .catch(() => {
                errorCallback('Something went wrong.');
            })
    }

    const [isDateTimePickerOpen, setIsDateTimePickerOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date(getFormattedDatePicker(task.date)));
    const [selectedTime, setSelectedTime] = useState(getFormattedTimePicker(task.date));
    const [convertedSelectedTime, setConvertedSelectedTime] = useState(getConvertedDuration(getFormattedTimePicker(task.date)));

    const [isTaskNameEditing, setIsTaskNameEditing] = useState(false);
    const [isTaskNameSaving, setIsTaskNameSaving] = useState(false);

    const [printFailVideoUrl, setPrintFailVideoUrl] = useState(null);
    const [isPrintFailVideoLoading, setIsPrintFailVideoLoading] = useState(false);

    const handleDateTimePickerClose = async () => {
        setIsDateTimePickerOpen(false)
        if(task.date !== selectedDate / 1000 + convertedSelectedTime)
            await patchStartTask(task.id, selectedDate / 1000 + convertedSelectedTime)
                .then(() => {
                    successCallback('The starting date has been updated.');
                })
                .catch(() => {
                    errorCallback('Something went wrong.');
                })
    }

    let totalPieceFile = 0;

    if(task.pic && task.pic.card_platform_pieces){
        task.pic.card_platform_pieces.forEach((piece) => {
            totalPieceFile += piece.copies;
        })
    }

    const handleNameChangeSave = (data) => {
        if(data.name && (data.name !== task.name)) {
            setIsTaskNameSaving(true);
            patchTaskName(task.id, data.name)
                .then(() => {
                    refetchTask()
                        .then(() => {
                            setAlert({message: 'Task name saved', status: 'success', date: new Date()});
                            setIsTaskNameSaving(false);
                            setIsTaskNameEditing(false);
                            setIsTaskUpdated(true);
                        })
                        .catch(() => setIsTaskNameSaving(false));
                })
                .catch(() => {
                    setAlert({message: 'Task name not saved: an error occurred.', status: 'error', date: new Date()});
                    setIsTaskNameSaving(false);
                });
        } else {
            setAlert({message: 'Task name not saved (name didn\'t change).', status: 'warning', date: new Date()});
        }
    }

    const getExternalTaskNameBlock = () => {
        if(isTaskNameEditing) {
            return(
              <span className="order-information-name">
                  <form onSubmit={handleSubmit(handleNameChangeSave)}>
                      <Controller
                          control={control}
                          name="name"
                          rules={{required: true}}
                          defaultValue={task.name ? task.name : ''}
                          render={({field}) => (
                              <input className="order-information-name-input" type="text" {...field}/>
                          )}
                      />
                      <button className="order-information-name-input-save" type="submit">
                          {isTaskNameSaving ? <CircularProgress size={16}/> : 'Save'}
                      </button>
                  </form>
              </span>
            );
        } else {
            return(
                <span className="order-information-name">
                    <span>{task.name}</span>
                    <button className="order-information-name-button" onClick={() => setIsTaskNameEditing(true)}>
                        <MaterialIcon label="edit"/>
                    </button>
                </span>
            );
        }
    }

    const loadPrintFailVideo = () => {
        setIsPrintFailVideoLoading(true);
        fetch(process.env.REACT_APP_API_ADDRESS + `print_fails/${printFails.data[0].id}/video`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .catch(error => {
                setAlert({message: 'Video not found on the server.', status: 'error', date: new Date()});
                setIsPrintFailVideoLoading(false);
            })
            .then(response => response.blob())
            .then(blob => {
                const {URL: {createObjectURL, revokeObjectURL}, setTimeout} = window;
                const url = createObjectURL(blob);
                setPrintFailVideoUrl(url);
                setIsPrintFailVideoLoading(false);
                setTimeout(() => {revokeObjectURL(url);}, 100);
            });
    };

    const taskProfile = getTaskProfile(task);

    if(task.printer.farm_cell !== undefined && farmEvents.isLoading) return <Loading/>
    if(farmEvents.isError) return <Error errorMessage={farmEvents.error}/>
    if(task.printer.farm_cell !== undefined && printFails.isLoading) return <Loading/>
    if(printFails.isError) return <Error errorMessage={printFails.error}/>

    const allEvents = [...task.events, ...(task.printer.farm_cell !== undefined ? farmEvents.data.map(e => {
        e.start = e.date;
        e.title = <span className="sf-event">{getEventType(e.type, e.value) + (e.user ? ' (' + e.user.firstname + ' ' + e.user.lastname + ')' :
            e.is_automatic ? ' (Automation)' : '')}</span>;
        e.after_launch = true;
        e.is_smart_farm_event = true;
        return e;
    }) : [])].sort((e1, e2) => e1.start - e2.start);

    return(
        <div>
            <div className="block">
                <h2>Platform parts and files</h2>
                {
                    selectedPiece &&
                        <div style={{height: 200}}>
                            <PieceViewer piece={selectedPiece}/>
                        </div>
                }
                {
                    <table>
                        <tbody>
                            {(task.pic && task.pic.card_platform_pieces) &&
                                task.pic.card_platform_pieces.map((platform) => (
                                <tr key={platform.piece.id}>
                                    <td>{platform.piece.name} (x{platform.copies})</td>
                                    <td>
                                        <button className="button-link button-icon" onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            downloadAPIFile(
                                                `pieces/${platform.piece.id}/download`,
                                                platform.piece.name
                                            );
                                        }}>
                                            <i className="fa fa-cloud-download-alt"/>
                                        </button>
                                    </td>
                                    <td onClick={() => {
                                        setSelectedPiece(platform.piece);
                                    }}>
                                        {selectedPiece && selectedPiece.id === platform.piece.id ? <VisibilityBlue style={{cursor: 'pointer', width: 24, height: 24}}/> : <VisibilityBlack style={{cursor: 'pointer'}}/>}
                                    </td>
                                </tr>
                            ))}
                            {(task.pic && task.pic.card_platform_attachments) &&
                                task.pic.card_platform_attachments.map((card_platform_attachment) => (
                                    <tr key={card_platform_attachment.id}>
                                        <td>{card_platform_attachment.attachment.name}</td>
                                        <td>
                                            <button className="button-link button-icon" onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                downloadAPIFile(
                                                    `attachments/${card_platform_attachment.attachment.id}/download`,
                                                    card_platform_attachment.attachment.name
                                                );
                                            }}>
                                                <i className="fa fa-cloud-download-alt"/>
                                            </button>
                                        </td>
                                        <td/>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                }
            </div>
            <div className="block">
                <h2>Order information</h2>
                <table className="table--compact">
                    <tbody>
                        <tr>
                            <td>Name</td>
                            <td>{getExternalTaskNameBlock()}</td>
                        </tr>
                        <tr>
                            <td>Created by</td>
                            <td>
                                {
                                    !isSmartFarmUser(task.user) ?
                                        <Avatar user={task.user} size="small"/> :
                                        <i>Job detected automatically</i>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Creation date</td>
                            <td>
                                {getFormattedDate(task.date_add)}
                            </td>
                        </tr>

                        {task.platform && Object.entries(task.platforms).length > 0 &&
                            <tr>
                                <td>Project(s)</td>
                                <td>
                                    {task.project ?
                                        <span className="project__name" style={{background: task.project.color.hex_code}}>{task.project.name}</span>
                                        : 'Aucun'
                                    }
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="block">
                <h2>Manufacturing details</h2>
                <table className="table--compact">
                    <tbody>
                        <tr>
                            <td>Printer</td>
                            <td>
                                <div>{getFormattedPrinterName(task.printer)}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>Profile</td>
                            <td>
                                <div>{taskProfile !== null ? taskProfile.name : <i>No profile defined</i>}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>Materials</td>
                            <td>
                                <div>{getFormattedMaterialsName(task)}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>Starting date</td>
                            {isTaskExternalTask ?
                                <td>{getFormattedRoundedDate(task.date)}</td> :
                                <td style={{cursor: 'pointer'}} onClick={() => setIsDateTimePickerOpen(true)}>
                                    {getFormattedRoundedDate(task.date)} <i className="fa fa-pencil-alt"/>
                                </td>
                            }
                        </tr>
                        <tr>
                            <td>Estimated end</td>
                            <td>{task.duration ? getFormattedRoundedDate(task.date + task.duration) : '--'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {
                task.printer.farm_cell !== undefined &&
                    <div className="block task-events">
                        <h2>Print fail detection</h2>
                        <div>
                            {
                                printFails.data.length > 0 ?
                                    <>
                                        <Alert severity="error">Print fail detected: {getFormattedFullFrDate(printFails.data[0].date)}</Alert>
                                        {
                                            isPrintFailVideoLoading ? <Loading/>
                                            : printFailVideoUrl !== null ?
                                                <video width="100%" height="100%" controls>
                                                    <source src={printFailVideoUrl} type="video/mp4"/>
                                                </video>
                                            : <button onClick={loadPrintFailVideo}>Load print fail video</button>
                                        }
                                    </>
                                :
                                    <Alert severity="success">No print fail detected.</Alert>
                            }
                        </div>
                    </div>
            }
            <div className="block task-events">
                <h2>Production events</h2>
                <div>
                    {
                        allEvents.map((event, index) => {
                            const isPast = event.start < getCurrentTimestamp();
                            const launchIsLate = task.date < getCurrentTimestamp() && !task.start_validated;
                            const endIsLate = task.end_date < getCurrentTimestamp() && !task.end_validated;
                            const isLate = (launchIsLate && event.after_launch) || (endIsLate && event.after_stop);
                            const isSF = event.is_smart_farm_event;

                            return (
                                <div className={`task-events__event ${isPast ? 'past' : ''} ${isLate ? 'late' : ''} ${isSF ? 'sf-event' : ''}`} key={index}>
                                    <div>
                                        {getFormattedFullFrDate(event.start)}
                                    </div>
                                    <div>
                                        {isLate && !isSF ? <i className="fa fa-times"/> :
                                            isPast && !isSF ? <i className="fa fa-check"/> : ''}
                                        {event.title}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="block">
                <h2>Notes</h2>
                <textarea id="f-comments" placeholder="Write here..." onBlur={handleEditComment}>{task.comments}</textarea>
            </div>
            <DateTimePickerComponent
                isDateTimePickerOpen={isDateTimePickerOpen} handleClose={handleDateTimePickerClose} selectedTime={selectedTime}
                selectedDate={selectedDate} setSelectedDate={setSelectedDate} setSelectedTime={setSelectedTime} setConvertedSelectedTime={setConvertedSelectedTime}
            />
        </div>
    )
}
