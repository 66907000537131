import {OutlinedCard} from "../../common/OutlinedCard";
import {Toggle} from "rsuite";
import React, {useMemo, useState} from "react";
import {sendFarmCommandEvent} from "../../../../services/socketEventsService";
import {
    areAllSoundModulesInactive,
    FARM_EVENT_TYPES,
    getCellsIdFromFarmStructure,
    SMART_FARM_COMMANDS
} from "../../../../services/FarmService";
import {isScannerModeEnabled} from "../../../CommonFunctions";
import {DigitCode} from "../../../common/DigitCode";
import {postFarmEvent} from "../../../../api/apiFarmCommand";
import {useSmartFarmData} from "../../../../contexts/SmartFarmDataContext";

export const FarmManagerBlock = (props) => {

    const {farmStructure} = props;

    const [isOpenDoorDigitCodeVisible, setIsOpenDoorDigitCodeVisible] = useState(false);

    const {smartFarmData} = useSmartFarmData();

    const isSoundModuleActive = useMemo(() => {
        return !areAllSoundModulesInactive(smartFarmData);
    }, [smartFarmData]);

    const [isScannerModeOn, setIsScannerModeOn] = useState(isScannerModeEnabled());

    const handleChangeSoundModuleIsActive = (isOn) => {
        sendFarmCommandEvent('broadcast', SMART_FARM_COMMANDS.SOUND_MODULE_ACTIVE, isOn ? 1 : 0);
    };

    /* Store scanner mode activation in local storage */
    const handleScannerModeChange = (isScannerMode) => {
        if(isScannerMode) {
            localStorage.setItem('scanner_mode', 'true');
        } else {
            localStorage.setItem('scanner_mode', 'false');
        }
        setIsScannerModeOn(isScannerMode);
    }

    const handleOpenAllDoors = (user) => {
        const userId = user.id ? user.id : null;

        let systemCodes = [];

        for(const farmModule of farmStructure.farm_modules){
            for(const farmCell of farmModule.farm_cells){
                systemCodes.push(farmCell.system_code);
            }
        }

        const cellsIds = getCellsIdFromFarmStructure(farmStructure);

        for(const systemCode of systemCodes){
            if(systemCode.startsWith('T') || systemCode.startsWith('R'))
                sendFarmCommandEvent(systemCode, SMART_FARM_COMMANDS.OPEN_DOOR, 0);
            postFarmEvent(cellsIds[systemCode], FARM_EVENT_TYPES.OPEN_DOOR, 0, userId)
        }
    };

    return(
        <OutlinedCard>
            <DigitCode
              visible={isOpenDoorDigitCodeVisible} setVisible={setIsOpenDoorDigitCodeVisible}
              callback={handleOpenAllDoors} errorCallback={() => null}
            />

            <h2>General Manager</h2>

            <div className="farm__dialog_farm_manager farm__dialog_common">
                <div>
                    <div className="toggle-label-right farm-command-line scanner-toggle">
                        <Toggle checked={isScannerModeOn} onChange={isScannerMode => handleScannerModeChange(isScannerMode)}/>
                        <span className="toggle-label">Receive data from scanner</span>
                    </div>

                    <div className="toggle-label-right farm-command-line scanner-toggle">
                        <Toggle checked={isSoundModuleActive} onChange={isOn => handleChangeSoundModuleIsActive(isOn)}/>
                        <span className="toggle-label">Active voice and buzzer</span>
                    </div>
                </div>

                <button type="button" onClick={() => setIsOpenDoorDigitCodeVisible(true)}>
                    Open all doors
                </button>
            </div>
        </OutlinedCard>
    );
}