import React from "react";
import ReactApexChart from "react-apexcharts";
import {Loading} from "../../../common/Loading";
import {CHARTS_UNIT_PERIODS, FARM_EVENT_TYPES} from "../../../../services/FarmService";

export const FarmDialogGraph = (props) => {

    const {data, farmEvents, label, color, ymin, ymax, unitPeriod, chartHeight, annotations} = props;

    let commandsList = [];

    if(data && data.length > 0 && farmEvents){
        const sortedFarmEvents = farmEvents.filter(e => (e.type === FARM_EVENT_TYPES.TEMPERATURE && e.value === 0) || e.farm_profile)
            .sort((e1, e2) => e1.date - e2.date);
        const startDate = data[0][0] / 1000;
        const endDate = data[data.length - 1][0] / 1000;

        // Find first relevant event
        let startIndex = 0;
        while(startIndex < sortedFarmEvents.length && sortedFarmEvents[startIndex].date < startDate)
            startIndex++;

        // Find last relevant event
        let endIndex = startIndex;
        while(endIndex < sortedFarmEvents.length && sortedFarmEvents[endIndex].date < endDate)
            endIndex++;

        const newFarmEvents = sortedFarmEvents.slice(startIndex, endIndex);

        // Fill until the edge of the graph
        if(startIndex > 0 && sortedFarmEvents[startIndex - 1].value !== 0)
            newFarmEvents.unshift({date: startDate, value: sortedFarmEvents[startIndex - 1].value});
        if(endIndex > 0 && endIndex === sortedFarmEvents.length)
            newFarmEvents.push({date: endDate, value: sortedFarmEvents[sortedFarmEvents.length - 1].value});

        // Create the commands list
        if(newFarmEvents.length > 0){
            let lastCommandEvent = newFarmEvents[0];

            for(let i=0; i < newFarmEvents.length; i++){
                const event = newFarmEvents[i];

                // New desired temperature
                if(event.value !== 0){
                    commandsList.push([event.date * 1000, event.value]);
                    lastCommandEvent = event;
                }
                // Reset
                else{
                    commandsList.push([event.date * 1000, lastCommandEvent.value]);
                    commandsList.push([event.date * 1000, null]);
                }
            }
        }
    }

    const axisColor = '#8D8D8D';

    const series = [{
        name: label, type: 'area',
        data: unitPeriod === CHARTS_UNIT_PERIODS.HOUR ? data.map(d => [d[0] + 2 * 3600 * 1000, d[1]]) : data
    }];

    if(commandsList.length > 0)
        series.push({
            name: 'Profile defined',
            type: 'line',
            data: CHARTS_UNIT_PERIODS.HOUR ? commandsList.map(d => [d[0] + 2 * 3600 * 1000, d[1]]) : commandsList
        });

    const chartData = {
        id: 'chart-environment',
        series: series,
        options: {
            chart: {
                id: 'area-datetime',
                height: 150,
                width: '100%',
                zoom: {autoScaleYaxis: true},
                toolbar: {
                    show: false
                },
                animations: {
                    enabled: false
                }
            },
            stroke: {
                curve: ['smooth', 'stepline'],
            },
            dataLabels: {enabled: false},
            xaxis: {
                type: 'datetime',
                tickAmount: 5,
                labels: {
                    style: {
                        colors: axisColor
                    },
                    format: unitPeriod === CHARTS_UNIT_PERIODS.HOUR ? 'HH:mm' : 'dd/MM',
                }
            },
            yaxis: {
                min: ymin,
                max: ymax,
                tickAmount: 4,
                labels: {
                    style: {
                        colors: axisColor,
                    }
                }
            },
            tooltip: {
                x: {format: 'dd/MM/yy HH:mm'}
            },
            colors: [color, 'black'],
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 100]
                }
            }
        }
    };

    if(annotations) {
        chartData.options.annotations = annotations;
    }

    if(data === undefined || data === null)
        return <Loading/>;

    return <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={chartHeight ? chartHeight : 150} width="100%"/>;
}
