const LineAdd = () => (
    <svg width="60" height="160" viewBox="0 0 60 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30 0L30 106" stroke="#8D8D8D" stroke-width="2" stroke-dasharray="12 8"/>
    <g filter="url(#filter0_d)">
    <path d="M10 126C10 114.954 18.9543 106 30 106C41.0457 106 50 114.954 50 126C50 137.046 41.0457 146 30 146C18.9543 146 10 137.046 10 126Z" fill="#00CAC0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M41.6673 127.666H31.6673V137.666H28.334V127.666H18.334V124.333H28.334V114.333H31.6673V124.333H41.6673V127.666Z" fill="#F2F2F2"/>
    </g>
    <defs>
    <filter id="filter0_d" x="0" y="100" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="5"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0.1 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    </defs>
    </svg>
);

export default LineAdd;
