import {getSortedResources} from "../../components/CommonFunctions";

/**
 * Get task string status
 * @param task
 * @returns {string}
 */
export const getTaskStatus = (task) => {
    if(task.status !== undefined && task.status !== null) {
        if(task.status === 'upcoming') {
            return 'Incoming';
        } else if(task.status === 'progress') {
            return 'In progress';
        }
        return 'Done';
    }
    return '';
}

/**
 * Get resources corresponding to task's material
 * @param brandId
 * @param materialId
 * @param colorId
 * @param resources
 * @returns {*}
 */
export const getTaskMaterialResources = (brandId, materialId, colorId, resources) => {
    if(resources && resources.length > 0) {
        const sortedResources = getSortedResources(resources);
        if(colorId !== null) {
            if(sortedResources[materialId] && sortedResources[materialId][brandId] && sortedResources[materialId][brandId][colorId]) {
                return sortedResources[materialId][brandId][colorId].map(resource =>
                    formatSelectLists(resource.id, resource.serial_number)
                );
            }
        } else {
            if(sortedResources[materialId] && sortedResources[materialId][brandId]) {
                return sortedResources[materialId][brandId].map(resource => (
                    formatSelectLists(resource.id, resource.serial_number)
                ));
            }
        }
    }
    return [];
}

/**
 * Format data for rsuite select
 * @param value
 * @param label
 * @returns {{label, value}}
 */
export const formatSelectLists = (value, label) => {
    return {value: value, label: label};
}

/**
 * Get available materials depending on technology
 * @param resources
 * @param printerTechnologyId
 * @returns {*[]}
 */
export const getMaterialsList = (resources, printerTechnologyId) => {
    let materials = [];
    if(resources && resources.length > 0) {
        let filteredResources = resources.filter(resource => resource.material.technology.id === printerTechnologyId);
        for(let resource of filteredResources) {
            let isNewMaterial = true;
            for(let material of materials) {
                if(material.value === resource.material.id) {
                    isNewMaterial = false;
                    break;
                }
            }
            if(isNewMaterial) {
                materials.push(formatSelectLists(resource.material.id, resource.material.name));
            }
        }
    }
    return materials;
}

/**
 * Get available brands depending on material
 * @param resources
 * @param taskMaterialId
 * @returns {*[]}
 */
export const getBrandsList = (resources, taskMaterialId) => {
    let brands = [];
    if(resources && resources.length > 0) {
        let filteredResources = resources.filter(resource => resource.material.id === taskMaterialId);
        for(let resource of filteredResources) {
            let isNewBrand = true;
            for(let brand of brands) {
                if(brand.value === resource.brand.id) {
                    isNewBrand = false;
                    break;
                }
            }
            if(isNewBrand) {
                brands.push(formatSelectLists(resource.brand.id, resource.brand.name));
            }
        }
    }
    return brands;
}

/**
 * Get available colors depending on material and brand
 * @param resourceIndex
 * @param resources
 * @param materialId
 * @param brandId
 * @returns {*[]}
 */
export const getColorsList = (resourceIndex, resources, materialId, brandId) => {
    let colors = [];
    if(resources && resources.length > 0) {
        let filteredResources = resources.filter(resource => resource.material.id === materialId && resource.brand.id === brandId);
        for(let resource of filteredResources) {
            let isNewColor = true;
            for(let color of colors) {
                if(color.value === resource.color.id) {
                    isNewColor = false;
                    break;
                }
            }
            if(isNewColor) {
                colors.push(formatSelectLists(resource.color.id, resource.color.color_name));
            }
        }
    }
    return colors;
}

/**
 * Check if a material is fully selected (material, brand and color if material with color)
 * @returns {null|*|boolean}
 * @param material
 * @param brand
 * @param hasColor
 * @param color
 */
export const isMaterialFullSelected = (material, brand, hasColor, color) => {
    return material && brand && (!hasColor || color);
}

/**
 *
 * @param resourceIndex
 * @param task
 * @returns {string}
 */
export const formatTaskMaterialValue = (resourceIndex, task) => {
    if (resourceIndex === 1)
        return (task.material1.name + ' - ' + task.brand1.name) + (task.printer.technology.has_color ? (' - ' + task.color1.color_name) : '');
    else
        return (task.material2.name + ' - ' + task.brand2.name) + (task.printer.technology.has_color ? (' - ' + task.color2.color_name) : '');
}

export const getReference = (material, brand, color) => {
    const colorStr = color ? color.color_name : 'nocolor';
    return JSON.stringify({'material': material.name, 'brand': brand.name, 'color': colorStr});
}

export const getResourcesRemainingQuantityByReference = (resources) => {
    let quantities = {};

    for(const resource of resources){
        const reference = getReference(resource.material, resource.brand, resource.color);
        if(!(reference in quantities)) quantities[reference] = 0;

        quantities[reference] += resource.remaining_quantity;
    }

    return quantities;
}