import {getCurrentTimestamp, getFormattedDate} from "../../CommonFunctions";
import {getTaskStatus} from "../../../services/gantt/ResourceChoiceService";
import {ResourceChoiceTaskResource} from "./ResourceChoiceTaskResource";
import {patchTaskValidateChosenResource, patchTaskValidateNoChosenResource} from "../../../api/apiGantt";
import React, {useEffect, useState} from "react";
import {CircularProgress} from "@material-ui/core";
import {MaterialIcon} from "../../common/icons/MaterialIcon";
import {patchResourceRemainingQuantity} from "../../../api/apiResource";

export const ResourceChoiceTask = (props) => {

    const {task, resources, refetchTasks, setAlert} = props;

    // Form states
    const [isTaskClosing, setIsTaskClosing] = useState(false);
    const [isTaskSaving, setIsTaskSaving] = useState(false);
    const [isTaskResourceUpdated, setIsTaskResourceUpdated] = useState(false);

    // Values state
    const [selectedMaterial1Id, setSelectedMaterial1Id] = useState(null);
    const [selectedBrand1Id, setSelectedBrand1Id] = useState(null);
    const [selectedColor1Id, setSelectedColor1Id] = useState(null);
    const [selectedMaterial2Id, setSelectedMaterial2Id] = useState(null);
    const [selectedBrand2Id, setSelectedBrand2Id] = useState(null);
    const [selectedColor2Id, setSelectedColor2Id] = useState(null);

    const [chosenResourcesIdsSlot1, setChosenResourcesIdsSlot1] = useState([]);
    const [chosenResourcesIdsSlot2, setChosenResourcesIdsSlot2] = useState([]);

    useEffect(() => {
        if(isTaskClosing) {
            // Request to validate no chosen resource
            patchTaskValidateNoChosenResource(task.id)
                .then(() => refetchTasks(getCurrentTimestamp()))
                .catch(() => {
                    // Invalidate close
                    setAlert({message: 'Task resource choice could not processed', status: "error", date: new Date()});
                    setIsTaskClosing(false);
                });
        }
    }, [isTaskClosing]);

    useEffect(() => {
        if(isTaskSaving) {
            let material1 = {};
            let material2 = {};
            if(!task.pic) {
                material1 = {materialId: selectedMaterial1Id, brandId: selectedBrand1Id, colorId: selectedColor1Id};
                material2 = {materialId: selectedMaterial2Id, brandId: selectedBrand2Id, colorId: selectedColor2Id};
            }

            // Save task resources
            patchTaskValidateChosenResource(task.id, chosenResourcesIdsSlot1, chosenResourcesIdsSlot2, material1, material2)
                .then(() => {
                    setAlert({message: 'Task resources saved', status: "success", date: new Date()});
                    refetchTasks();
                })
                .catch(() => {
                    // Invalidate save
                    setAlert({message: 'Task resources could not be saved', status: "error", date: new Date()});
                    setIsTaskSaving(false);
                });

            // Update resources remaining quantity
            for(const resourceId of chosenResourcesIdsSlot1){
                const remainingQuantity = resources.filter(r => r.id === resourceId)[0].remaining_quantity;
                patchResourceRemainingQuantity(resourceId, Math.max(parseInt(remainingQuantity) - task.quantity1, 0));
            }
            for(const resourceId of chosenResourcesIdsSlot2){
                const remainingQuantity = resources.filter(r => r.id === resourceId)[0].remaining_quantity;
                patchResourceRemainingQuantity(resourceId, Math.max(parseInt(remainingQuantity) - task.quantity2, 0));
            }
        }
    }, [isTaskSaving]);

    useEffect(() => {
        // Check if a resource choice has been made (even if partial choice)
        const isResourceChoiceMade =
            // External tasks
            (!task.pic && (selectedMaterial1Id !== null || selectedMaterial2Id !== null)) ||
            // Gantt tasks
            (task.pic && (chosenResourcesIdsSlot1.length > 0 ||
            (task.printer.extruders_number === 2 && chosenResourcesIdsSlot2.length > 0)))
        ;
        // Update state
        if(isTaskResourceUpdated !== isResourceChoiceMade) {
            setIsTaskResourceUpdated(isResourceChoiceMade);
        }
    }, [selectedMaterial1Id, selectedBrand1Id, selectedColor1Id, selectedMaterial2Id, selectedBrand2Id,
        selectedColor2Id, chosenResourcesIdsSlot1, chosenResourcesIdsSlot2]);

    const handleCloseButtonClick = () => {
        setIsTaskClosing(true);
    }

    const handleSaveButtonClick = () => {
        setIsTaskSaving(true);
    }

    return(
        <div className="resource-choice-task">
            <div className="resource-choice-task-content">
                {/* Header */}
                <div className="resource-choice-task-header">
                    <div>
                        <span className="resource-choice-task-name">{task.name}</span>
                        <span className="resource-choice-task-description-date">{task.date ? ('(' + getFormattedDate(task.date) + ')') : ''}</span>
                        <span>{getTaskStatus(task)}</span>
                    </div>
                    {!isTaskClosing &&
                        <button className="resource-choice-task-close-button" onClick={() => handleCloseButtonClick()}>
                            <MaterialIcon label="close" classes="resource-choice-task-close-button-icon"/>
                        </button>
                    }
                    {isTaskClosing &&
                        <div className="resource-choice-task-close-loader">
                            <CircularProgress size={18}/>
                        </div>
                    }
                </div>
                {/* First resource */}
                <ResourceChoiceTaskResource
                    task={task} resources={resources} resourceIndex={1} chosenResourcesIds={chosenResourcesIdsSlot1}
                    setChosenResourcesIds={setChosenResourcesIdsSlot1} printerTechnology={task.printer.technology}
                    selectedMaterialId={selectedMaterial1Id} setSelectedMaterialId={setSelectedMaterial1Id}
                    selectedBrandId={selectedBrand1Id} setSelectedBrandId={setSelectedBrand1Id}
                    selectedColorId={selectedColor1Id} setSelectedColorId={setSelectedColor1Id}
                />

                {/* Second resource */}
                {task.printer.extruders_number === 2 &&
                    <div className="resource-choice-task-second-resource">
                        <ResourceChoiceTaskResource
                            task={task} resources={resources} resourceIndex={2} chosenResourcesIds={chosenResourcesIdsSlot2}
                            setChosenResourcesIds={setChosenResourcesIdsSlot2} printerTechnology={task.printer.technology}
                            selectedMaterialId={selectedMaterial2Id} setSelectedMaterialId={setSelectedMaterial2Id}
                            selectedBrandId={selectedBrand2Id} setSelectedBrandId={setSelectedBrand2Id}
                            selectedColorId={selectedColor2Id} setSelectedColorId={setSelectedColor2Id}
                        />
                    </div>
                }
                <div>
                    <button className="resource-choice-task-save-button" onClick={() => handleSaveButtonClick()}
                        disabled={!isTaskResourceUpdated}>
                        {isTaskSaving ?
                            <CircularProgress size={14}/> :
                            <span>Save</span>
                        }
                    </button>
                </div>
            </div>
        </div>
    );
}