import {InfluxDB} from "@influxdata/influxdb-client-browser";
import {SMART_FARM_DATA_FIELDS} from "../services/FarmService";
import {getCurrentTimestamp} from "../components/CommonFunctions";

// REQUEST FUNCTIONS

/**
 * get last data found in data range (one data per field)
 * @param systemCodes
 * @param fields (ex: temperature, humidity, ...)
 * @param date
 * @param measurement (equivalent to database table)
 * @param start
 * @returns {Promise<Array<any>>}
 */
export const getSingleInfluxdbDataBySystemCode = (systemCodes, fields = ["temperature"], date = "now()",
                                                  measurement = "STM", start = '-2m') => {

    let request = _prebuildFluxRequestWithSystemCode(systemCodes, fields, start, date, measurement);
    request += '|> last()';
    return _getQueryApi().collectRows(_endFluxRequest(request));
}

/**
 * get data in data range (one data per field)
 * @param systemCodes
 * @param fields (ex: temperature, humidity, ...)
 * @param window (period for one single data - ex: 1m = one data per minute, each data is an average of all existing data in that period)
 * @param start (data range start time)
 * @param stop (data range stop time)
 * @param measurement (equivalent to database table)
 * @returns {Promise<Array<any>>}
 */
export const getInfluxdbDataBySystemCode = (systemCodes, fields = ["temperature"], window = "1m",
                                            start = "-30m", stop = "now()", measurement = "STM") => {
    let request = _prebuildFluxRequestWithSystemCode(systemCodes, fields, start, stop, measurement);
    request += `|> aggregateWindow(every: ${window}, fn: mean, createEmpty: false)`;
    request += '|> group(columns: ["_field"], mode:"by")';
    return _getQueryApi().collectRows(_endFluxRequest(request));
}

/**
 * get last printer status data for all printers in influxdb
 * @param printersIds
 * @returns {Promise<Array<unknown>>}
 */
export const getPrintersStatusData = (printersIds) => {
    let request = _prebuildFluxMeasurementRequest('-5m', 'now()', 'printers');
    request = _prebuildFluxFilterRequest(request, printersIds, 'printer_id');
    request = _prebuildFluxFilterRequest(request, ['printer_status']);
    request += '|> last()';
    return _getQueryApi().collectRows(_endFluxRequest(request));
}

/**
 * Get data with specific flux request
 * @param fluxQuery
 * @returns {Promise<Array<any>>}
 */
export const _getInfluxdbData = (fluxQuery) => {
    return _getQueryApi().collectRows(fluxQuery);
}


// INTERNAL FUNCTIONS

/* Build request body (flux query) */
const _prebuildFluxRequestWithSystemCode = (systemCodes, fields, start, stop, measurement) => {
    let request = _prebuildFluxMeasurementRequest(start, stop, measurement);
    request = _prebuildFluxFilterRequest(request, systemCodes, 'system_code');
    return _prebuildFluxFilterRequest(request, fields);
}

const _prebuildFluxMeasurementRequest = (start, stop, measurement) => {
    let bucket = process.env.REACT_APP_INFLUX_BUCKET;

    // Start with a timestamp (seconds) or string < -7d: get data in the downsampled bucket
    if(
        (Number.isInteger(start) && start < getCurrentTimestamp() - 24 * 3600 * 7) ||
        (/-([89]|\d{2,})d/.test(start))
    ){
        bucket += '_downsampled';
    }

    return (
        `from(bucket: "${bucket}") ` +
        `|> range(start: ${start}, stop: ${stop}) ` +
        `|> filter(fn: (r) => r["_measurement"] == "${measurement}") `
    );
}

const _prebuildFluxFilterRequest = (request, filterValues, column = '_field') => {
    if(filterValues !== '*'){
        request += '|> filter(fn: (r) =>';
        filterValues.map((filterValue, index) => {
            request +=  ` r["${column}"] == "${filterValue}" `;
            if(index < filterValues.length - 1) {
                request += ' or '
            } else {
                request += ')';
            }
        });
    }
    return request;
}

/* Add last flux query line to flux request */
const _endFluxRequest = (request) => {
    return request + '|> yield(name: "mean")';
}

/* Get Influxdb client query API */
const _getQueryApi = () => {
    return new InfluxDB({
        url: process.env.REACT_APP_INFLUX_PROTOCOL + '://' +
            process.env.REACT_APP_INFLUX_URL,
        token: process.env.REACT_APP_INFLUX_TOKEN
    }).getQueryApi(process.env.REACT_APP_INFLUX_ORG);
}

/**
 * Get fan usage in seconds by system code (fan used = fan value > 0)
 * @param systemCode
 * @param startDate
 * @returns {Promise<Array<*>>}
 */
export const getFanUsageInSeconds = (systemCode, startDate = '-1d') => {
    return _getInfluxdbData(
        'import "math"\n' +
        ` from(bucket: "${process.env.REACT_APP_INFLUX_BUCKET}_downsampled") ` +
        '  |> range(start: ' + startDate + ', stop: now())' +
        '  |> filter(fn: (r) => r["_measurement"] == "STM")' +
        '  |> filter(fn: (r) => r._field == "' + SMART_FARM_DATA_FIELDS.AIR_EXTRACTION +'")' +
        '  |> filter(fn: (r) => r["system_code"] == "' + systemCode + '")' +
        '  |> sort(columns: ["_time"], desc: true)' +
        '  |> elapsed(unit: 1s)' +
        '  |> filter(fn: (r) => r._value >= 15)' + // 15% = minimum utilization (30%) during half the time minimum for the aggregated period
        '  |> map(fn: (r) => ({ r with elapsed_abs: int(v: math.abs(x: float(v: r.elapsed)))}))' +
        '  |> group()' +
        '  |> sum(column: "elapsed_abs")'
    );
}

// DATA FORMAT FUNCTIONS

/* Return a response data indexed by system codes */
export const formatDataBySystemCode = (data) => {
    let result = {};

    data.map(d => {
        if(!(d.system_code in result))
            result[d.system_code] = {};

        result[d.system_code][d._field] = d._value;
        result[d.system_code]['time'] = getTimestampFromTimeValue(d._time);
    });

    return result;
};

export const formatDataListBySystemCode = (data) => {
    let result = {};

    data.map(d => {
        if(!(d.system_code in result))
            result[d.system_code] = {};

        if(!(d._field in result[d.system_code]))
            result[d.system_code][d._field] = [];

        result[d.system_code][d._field].push([getTimestampFromTimeValue(d._time), d._value]);
        result[d.system_code]['time'] = getTimestampFromTimeValue(d._time);
    });

    return result;
};

export const formatPrintersDataById = (data) => {
    let result = {};

    data.map(d => {
        if(!(d.printer_id in result))
            result[d.printer_id] = {};

        result[d.printer_id][d._field] = d._value;
        result[d.printer_id]['time'] = getTimestampFromTimeValue(d._time);
    });

    return result;
}

/* Get temperature value from response data (max 1 decimal) */
export const getFormattedTemperatureValue = (temperature) => {
    return temperature.toFixed(1);
}

/* Get temperature value from response data (round value) */
export const getFormattedHumidityValue = (humidity) => {
    return Math.round(humidity);
}

/* Get pollution value from response data (round value) */
export const getFormattedPollutionValue = (pollution) => {
    return Math.round(pollution);
}

/* Get tvoc value from response data (round value) */
export const getFormattedTvocValue = (tvoc) => {
    return Math.round(tvoc);
}

/* Get timestamp from response data, using app date format */
export const getTimestampFromTimeValue = (datetime) => {
    const date = new Date(datetime)
    return date.getTime();
}
