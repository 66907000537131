import {FarmModule} from "./FarmModule";
import React from "react";
import {FarmControlPanel} from "./FarmControlPanel";

export const FarmStructure = (props) => {

    const {farmStructure, handleClickOpenRightPart, setIsWeighModalOpen, openRightStructurePanel, farmMaintenances} = props;

    const isModuleFirstElement = (module) => {
        return module.position === 0 && !farmStructure.is_panel_side_left;
    }

    const isModuleLastElement = (module) => {
        return module.position === (farmStructure.farm_modules.length - 1) && farmStructure.is_panel_side_left;
    }

    return(
        <div>
            <div className="farm__structure">
                {farmStructure.is_panel_side_left &&
                    <FarmControlPanel
                        isPanelSideLeft={farmStructure.is_panel_side_left} openRightStructurePanel={openRightStructurePanel}
                    />
                }
                {(farmStructure.farm_modules && farmStructure.farm_modules.length > 0) &&
                    farmStructure.farm_modules.map(module =>
                        <FarmModule
                            key={module.id} farmModule={module}
                            handleClickOpenRightPart={handleClickOpenRightPart}
                            isModuleFirstElement={isModuleFirstElement(module)} isModuleLastElement={isModuleLastElement(module)}
                            isLastModule={module.position === (farmStructure.farm_modules.length - 1)}
                            setIsWeighModalOpen={setIsWeighModalOpen}
                            farmMaintenances={farmMaintenances}
                        />
                    )
                }
                {!farmStructure.is_panel_side_left &&
                    <FarmControlPanel
                        isPanelSideLeft={farmStructure.is_panel_side_left} openRightStructurePanel={openRightStructurePanel}
                    />
                }
            </div>
        </div>
    );
}
