import {fetchData} from "../api/useFetch";
import {getCurrentUser, isScannerModeEnabled} from "../components/CommonFunctions";
import {Subject} from "rxjs";

const { io } = require("socket.io-client");

let socket;
let isConnection = false;
let lastServerAppAckDate = Date.now();
let lastServerPythonAckDate = Date.now();

const startPrintSubject = new Subject(null);
const notificationsSubject = new Subject(null);
const receivedDataSubject = new Subject(null);

const getOptions = (token) => {
    return {
        auth: {
            token: token,
            client: 'app'
        },
        path: '/socket'
    }
};

/**
 *  Get socket server token from database depending on organization's user and initialize connection to server
 */
export const connectToSocketServerDependingOnUser = (userData) => {
    // Get socket server token
    fetchData('organizations/' + userData.organization.id + '/smart-farm-token')
        .then(response => {
            response.json().then(data => {
                if(data && data.smart_farm_socket_server_token) {
                    // Start socket server connection and connect to events
                    initializeSocketEventsConnection(data.smart_farm_socket_server_token);
                }
            });
        });
}

/**
 * Initialize connection to the socket server and listen to events
 */
export const initializeSocketEventsConnection = (token) => {
    // Allow only one connection for the app
    if(!isConnection) {
        if(!socket) {
            let url = process.env.REACT_APP_BASE_PROTOCOL + '://' + process.env.REACT_APP_BASE_URL;
            if(process.env.REACT_APP_NODE_EVENTS_PORT)
                url += ':' + process.env.REACT_APP_NODE_EVENTS_PORT;
            socket = io(url, getOptions(token))
        }

        socket.on('connect', () => {
            isConnection = true;
        });

        socket.on('connect_error', () => {
            isConnection = false;
        });

        socket.on('disconnect', () => {
            // Not mandatory since we only have this connection in the app
            // Warning: if we remove all listeners, we lose the connection with all events
            // socket.removeAllListeners();
        });

        socket.on('notification', (notificationData) => {
            notificationsSubject.next(notificationData);
        });

        socket.on('scanner', (data) => {
            if(isScannerModeEnabled() && data && data.serial_number !== undefined && data.serial_number !== null) {
                window.location = '/resources/' + data.serial_number;
            }
        });

        socket.on('start print', (data) => {
            startPrintSubject.next(data);
        });

        socket.on('received_data', (data) => {
            receivedDataSubject.next(data);
        });

        // Watchdog
        socket.on('app_watchdog_ack', (data) => {
            lastServerAppAckDate = Date.now();
        });

        socket.on('python_watchdog_ack', (data) => {
            lastServerPythonAckDate = Date.now();
        });

        if(process.env.REACT_APP_ENABLE_SOCKET_SERVER_WATCHDOG === 'true')
            setInterval(manageWatchdog, 9000);
    }
}

/**
 * Clear socket server connection
 */
export const closeSocketEventsConnection = () => {
    if(socket) {
        socket.off('connect');
        socket.off('farm command');
        socket.removeAllListeners();
        return socket.disconnect();
    }
}

/**
 * Send smart farm command event
 * @param systemCode
 * @param action
 * @param data
 */
export const sendFarmCommandEvent = (systemCode, action, data) => {
    socket.emit('farm command', {
        organization_group: process.env.REACT_APP_API_URI + `organization_groups/${getCurrentUser().organization.organization_group.id}`,
        system_code: systemCode,
        action: action,
        data: data,
        sent_date: Math.floor(Date.now() / 1000)
    });
};

export const getSocketStartPrintObservable = () => {
    return startPrintSubject.asObservable();
}

export const resetSocketStartPrintSubject = () => {
    startPrintSubject.next(null);
}

export const getSocketNotificationsObservable = () => {
    return notificationsSubject.asObservable();
};

export const getSocketReceivedDataObservable = () => {
    return receivedDataSubject.asObservable();
};

export const manageWatchdog = () => {
    // TODO Use lastServerPythonAckDate to manage deconnexion?
    // const maxDiff = Date.now() - Math.min(lastServerAppAckDate, lastServerPythonAckDate);
    const maxDiff = Date.now() - lastServerAppAckDate;

    if(maxDiff > 20000){
        console.error('Connection with Smart Farm lost. Reloading page.');
        window.location.reload();
    }

    socket.emit('app_watchdog', 1);
};

export const emitNotificationSocket = (notificationData) => {
    socket.emit('notification', notificationData);
};

export const sendPrintFile = (data) => {
    socket.emit('print_file', data);
};