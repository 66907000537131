import {OutlinedCard} from "../../common/OutlinedCard";
import {BUTTON_COLORS, SimpleButton} from "../../../common/buttons/SimpleButton";
import {getLastMaintenanceForEveryFarmCell,
    patchMaintenanceEnd,
    postMaintenanceStart
} from "../../../../api/apiMaintenance";
import React, {useEffect, useState} from "react";
import {ALERT_STATUS, getAlertContent, getFormattedDate, isUserGranted} from "../../../CommonFunctions";
import {sendFarmCommandEvent} from "../../../../services/socketEventsService";
import {
    isMaintenanceRunning,
    isSavedMaintenanceConfirmedBySmartFarmData,
    SMART_FARM_COMMANDS
} from "../../../../services/FarmService";
import {farmMaintenancesReducerActions} from "../../../../services/farm/FarmMaintenancesReducer";
import {MaintenancesHistoryTable} from "../tables/MaintenancesHistoryTable";
import {MaterialIcon} from "../../../common/icons/MaterialIcon";
import {DigitCode} from "../../../common/DigitCode";
import {useIsMounted} from "../../../../services/hooks/useIsMounted";
import {MaintenanceConfirmationModal} from "../modals/MaintenanceConfirmationModal";
import {SystemStatusBlock} from "./SystemStatusBlock";

export const MaintenanceBlock = (props) => {

    const {structureMaintenance, cellMaintenance, structureId, cellId, systemCode, setAlert, setFarmMaintenances,
        smartFarmDataMaintenance, farmCell} = props;

    const isCellBlock = (cellId !== undefined && cellId !== null);

    const isMounted = useIsMounted();

    const [isMaintenanceDigitCodeVisible, setIsMaintenanceDigitCodeVisible] = useState(false);

    const handleStartMaintenanceButtonClick = () => {
        setConfirmationModal({
            title: isCellBlock ? 'Start maintenance' : 'Start general maintenance',
            description: 'Do you confirm maintenance start? This will stop all system' + (isCellBlock ? ' in this environment.' : '.'),
            button: {label: 'Start maintenance', callback: () => setIsMaintenanceDigitCodeVisible(true)}
        });
    }

    const handleStopMaintenanceButtonClick = () => {
        setConfirmationModal({
            title: isCellBlock ? 'Stop maintenance' : 'Stop general maintenance',
            description: 'Do you confirm maintenance stop? This will stop restart all system' + (isCellBlock ? ' in this environment.' : '.'),
            button: {label: 'Stop maintenance', callback: handleStopMaintenance}
        });
    }

    const handleStartMaintenance = (user) => {
        const userId = user.id ? user.id : null;
        setIsMaintenanceDataValidated(null);
        // Send command to Smart Farm
        sendFarmCommandEvent((isCellBlock ? systemCode : 'broadcast'), SMART_FARM_COMMANDS.MAINTENANCE, 1);
        // Save maintenance in database and update app
        postMaintenanceStart(isCellBlock ? null : structureId, isCellBlock ? cellId : null, userId)
            .then(maintenance => {
                if(isMounted) {
                    setLastMaintenance(maintenance);
                    setIsInMaintenance(true);
                }
                if(isCellBlock) {
                    setFarmMaintenances({type: farmMaintenancesReducerActions.START_MAINTENANCE, value: {cellId: cellId, maintenance: maintenance}});
                    isMounted && setConfirmationModal(null)
                } else {
                    getLastMaintenanceForEveryFarmCell()
                        .then(farmCells => {
                            setFarmMaintenances({type: farmMaintenancesReducerActions.START_GENERAL_MAINTENANCE,
                                value: {structureMaintenance: maintenance, farmCells: farmCells}});
                        })
                        .catch(() => {
                            setFarmMaintenances({type: farmMaintenancesReducerActions.START_GENERAL_MAINTENANCE,
                                value: {structureMaintenance: maintenance, farmCells: null}});
                        })
                        .finally(() => isMounted && setConfirmationModal(null));
                }
            })
            .catch(() => {
                setAlert(getAlertContent('An error occurred while starting maintenance', ALERT_STATUS.ERROR));
                isMounted && setConfirmationModal(null)
            });
    }

    const handleStopMaintenance = () => {
        setIsMaintenanceDataValidated(null);
        // Send command to Smart Farm
        sendFarmCommandEvent((isCellBlock ? systemCode : 'broadcast'), SMART_FARM_COMMANDS.MAINTENANCE, 0);
        // Save maintenance in database and update app
        patchMaintenanceEnd(lastMaintenance ? lastMaintenance.id : null)
            .then(maintenance => {
                if(isMounted) {
                    setLastMaintenance(maintenance);
                    setIsInMaintenance(false);
                }
                setFarmMaintenances(isCellBlock ?
                    {type: farmMaintenancesReducerActions.STOP_MAINTENANCE, value: {cellId: cellId, maintenance: maintenance}} :
                    {type: farmMaintenancesReducerActions.STOP_GENERAL_MAINTENANCE, value: maintenance}
                );
            })
            .catch(() => setAlert(getAlertContent('An error occurred while stopping maintenance', ALERT_STATUS.ERROR)))
            .finally(() => isMounted && setConfirmationModal(null));
    }

    const maintenanceButtons = {
        START: {label: 'Start maintenance', icon: 'start', action: handleStartMaintenanceButtonClick},
        STOP: {label: 'Stop maintenance', icon: 'stop', action: handleStopMaintenanceButtonClick}
    };

    const isSavedMaintenanceDataValidatedBySmartFarm = () => {
        let maintenance;
        if(isCellBlock) {
            maintenance = structureMaintenance && cellMaintenance && structureMaintenance.end_date > cellMaintenance.end_date ? structureMaintenance : cellMaintenance;
        } else {
            maintenance = lastMaintenance;
        }
        return isSavedMaintenanceConfirmedBySmartFarmData(maintenance, smartFarmDataMaintenance);
    }

    // Maintenance data specific to displayed block (cell block : cell maintenance / structure block : structure maintenance)
    const [lastMaintenance, setLastMaintenance] = useState(isCellBlock ? cellMaintenance : structureMaintenance);
    const [isInMaintenance, setIsInMaintenance] = useState(isMaintenanceRunning(isCellBlock ? cellMaintenance : structureMaintenance));

    const [isMaintenanceDataValidated, setIsMaintenanceDataValidated] = useState(isSavedMaintenanceDataValidatedBySmartFarm());

    const [confirmationModal, setConfirmationModal] = useState(null);

    useEffect(() => {
        setIsMaintenanceDataValidated(isSavedMaintenanceDataValidatedBySmartFarm());
    }, [smartFarmDataMaintenance]);

    const getMaintenanceButton = (buttonData) => {
        return(
            <SimpleButton
                label={buttonData.label} color={BUTTON_COLORS.WARN} isOutlined={true} icon={buttonData.icon} padding={8}
                action={buttonData.action}
            />
        );
    }

    const getMaintenanceInfo = () => {
        if(isInMaintenance) {
            // Case: cell is in maintenance
            return {label: 'Maintenance start date:', date: lastMaintenance ? lastMaintenance.start_date : null};
        } else if(isCellBlock) {
            // Case: in cell block, check if a general maintenance is running
            if(isMaintenanceRunning(structureMaintenance)) {
                return {label: 'General maintenance start date:', date: structureMaintenance ? structureMaintenance.start_date : null};
            } else if(structureMaintenance || cellMaintenance) {
                // Case: a maintenance already happened, display last one between general and cell maintenances
                let isStructureMaintenance;
                if(structureMaintenance && cellMaintenance) {
                    isStructureMaintenance = structureMaintenance.end_date > cellMaintenance.end_date;
                } else {
                    isStructureMaintenance = structureMaintenance !== undefined && structureMaintenance !== null;
                }
                const label = isStructureMaintenance ? 'Last maintenance end date (general maintenance):' : 'Last maintenance end date:';
                return {label: label, date: isStructureMaintenance ? structureMaintenance.end_date : cellMaintenance.end_date}
            }
        }
        // Display last maintenance date (used in structure dialog)
        return {label: 'Last maintenance end date:', date: lastMaintenance ? lastMaintenance.end_date : null}
    }

    const getMaintenanceInfosBlock = () => {
        const infos = getMaintenanceInfo();
        return(
            <div className="maintenance-infos">
                <span className="maintenance-infos-label">{infos.label}</span>
                <span className="maintenance-infos-date">{infos.date ? getFormattedDate(infos.date) : '--'}</span>
            </div>
        );
    }

    const handleCloseConfirmationModal = () => {
        setConfirmationModal(null);
    }

    return(
        <OutlinedCard>
            <DigitCode
                visible={isMaintenanceDigitCodeVisible} setVisible={setIsMaintenanceDigitCodeVisible}
                callback={handleStartMaintenance} errorCallback={() => null}
            />
            <div className="farm__dialog_maintenance farm__dialog_common">
                <div className="header-block">
                    <h2>{isCellBlock ? 'Maintenance' : 'General Maintenance'}</h2>
                </div>
                <div className="maintenance-body">
                    {
                        isUserGranted('ROLE_MANAGE_SMART_FARM') &&
                        <>
                            {!(cellId && structureMaintenance && !structureMaintenance.end_date) ?
                                getMaintenanceButton(isInMaintenance ? maintenanceButtons.STOP : maintenanceButtons.START) :
                                <div>Smart Farm is in general maintenance</div>
                            }
                            {/* If maintenance is running: confirm smart farm is stopped */}
                            {(isMaintenanceDataValidated && isInMaintenance) &&
                                <div className="maintenance-confirm-success">
                                    <MaterialIcon label="check" classes="maintenance-confirm-success-icon"/>
                                    <span>Smart Farm hardware is stopped</span>
                                </div>
                            }
                            {/* If maintenance in bdd different from maintenance data from smart farm: display error message */}
                            {isMaintenanceDataValidated === false &&
                                <div className="maintenance-confirm-error">
                                    <MaterialIcon label="warning" classes="maintenance-confirm-error-icon"/>
                                    <span>{isInMaintenance ? 'Smart Farm hardware is not stopped' : 'Smart Farm hardware is stopped'}</span>
                                </div>
                            }
                        </>
                    }
                    {getMaintenanceInfosBlock()}
                    <MaintenancesHistoryTable cellId={cellId} structureId={structureId} isInMaintenance={isInMaintenance}/>
                </div>
            </div>
            {/* Confirmation modal */}
            {confirmationModal &&
                <MaintenanceConfirmationModal
                    title={confirmationModal.title}
                    description={confirmationModal.description}
                    buttons={[confirmationModal.button]}
                    cancelCallback={() => handleCloseConfirmationModal()}
                />
            }

            <SystemStatusBlock systemCode={farmCell ? farmCell.system_code : null}/>
        </OutlinedCard>
    );
}