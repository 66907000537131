const token = localStorage.getItem('token');

// Resource CRUD
export const postResourceBrand = async (brand) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + 'resource_brands', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            name: brand.name,
            brand_data: brand.brand_data ? process.env.REACT_APP_API_URI + 'resource_brand_datas/' + brand.brand_data : null
        })
    }).then((response) => {
        if(!response.ok)
        throw Error;
    })
}

export const deleteResourceBrand = async (resourceBrandId) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `resource_brands/${resourceBrandId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const patchResourceBrand = async (brand) => {
    const brandData = brand.brand_data !== null ? (process.env.REACT_APP_API_URI + 'resource_brand_datas/' + brand.brand_data) : null;

    await fetch(process.env.REACT_APP_API_ADDRESS + `resource_brands/${brand.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            name: brand.name,
            brand_data: brandData
        })
    }).then((response) => {
        if(!response.ok)
        throw Error;
    })
}

const getBrandsList = (brandIds) => {
    let brandsList = [];
    brandIds.forEach(brand => {
       brandsList.push(
           process.env.REACT_APP_API_URI + `resource_brands/${brand}`
       );
    });
    return brandsList;
}

// Technology CRUD
export const postTechnology = async (name, diameter, color, extruder, unit, brands) => {
    let resourceBrands = getBrandsList(brands);
    await fetch(process.env.REACT_APP_API_ADDRESS + 'technologies', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            name: name,
            resource_brands: resourceBrands,
            has_diameter: diameter === '1',
            has_color: color === '1',
            max_extruders_number: parseInt(extruder),
            unit: parseInt(unit)
        })
    }).then((response) => {
        if(!response.ok)
        throw Error;
    })
}

export const deleteTechnology = async (technoId) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `technologies/${technoId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const patchTechnology = async (id, name, diameter, color, nozzle, unit, brands) => {
    let resourceBrands = getBrandsList(brands);
    await fetch(process.env.REACT_APP_API_ADDRESS + `technologies/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            name: name,
            resource_brands: resourceBrands,
            has_diameter: diameter === '1',
            has_color: color === '1',
            max_extruders_number: parseInt(nozzle),
            unit: parseInt(unit)
        })
    }).then((response) => {
        if(!response.ok)
        throw Error;
    })
}


// Printer CRUD
export const postPrinterBrand = async (name) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + 'printer_brands', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            name: name
        })
    }).then((response) => {
        if(!response.ok)
        throw Error;
    })
}

export const deletePrinterBrand = async (printerBrandId) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `printer_brands/${printerBrandId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const patchPrinterBrand = async (name, printId) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `printer_brands/${printId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            name: name
        })
    }).then((response) => {
        if(!response.ok)
        throw Error;
    })
}

// Model CRUD
export const postModel = async (name) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + 'models', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            name: name
        })
    }).then((response) => {
        if(!response.ok)
        throw Error;
    })
}

export const deleteModel = async (modelId) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `models/${modelId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}


export const patchModel = async (name, modelId) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `models/${modelId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            name: name
        })
    }).then((response) => {
        if(!response.ok)
        throw Error;
    })
}

// Material CRUD
export const postMaterial = async (material) => {
    return await fetch(process.env.REACT_APP_API_ADDRESS + 'materials', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            technology: process.env.REACT_APP_API_URI + `technologies/${material.technology}`,
            name: material.name,
            material_data: material.material_data ? process.env.REACT_APP_API_URI + 'material_datas/' + material.material_data : null
        })
    }).then((response) => {
        if(!response.ok)
        throw Error;

        return response.json()
    })
}

export const deleteMaterial = async (materialId) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `materials/${materialId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const patchMaterial = async (material) => {
    const materialData = material.material_data !== null ? (process.env.REACT_APP_API_URI + 'material_datas/' + material.material_data) : null;

    await fetch(process.env.REACT_APP_API_ADDRESS + `materials/${material.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            technology: process.env.REACT_APP_API_URI + `technologies/${material.technology}`,
            name: material.name,
            material_data: materialData
        })
    }).then((response) => {
        if(!response.ok)
        throw Error;
    })
}

// Resource Data CRUD
export const postResourceBrandData = async (brand) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + 'resource_brand_datas', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            name: brand.name
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}

export const deleteResourceBrandData = async (resourceBrandId) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `resource_brand_datas/${resourceBrandId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}

export const patchResourceBrandData = async (brand) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `resource_brand_datas/${brand.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            name: brand.name
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}

// Material Data CRUD
export const postMaterialData = async (material) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + 'material_datas', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            name: material.name
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}

export const deleteMaterialData = async (materialId) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `material_datas/${materialId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}

export const patchMaterialData = async (material) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `material_datas/${material.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            name: material.name
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}

// Resource Empty Weight Data CRUD
export const postResourceEmptyWeightData = async (data) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + 'resource_empty_weight_datas', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            brand: process.env.REACT_APP_API_URI + `resource_brand_datas/${data.brand}`,
            material: (data.material && parseInt(data.material) !== 0) ? process.env.REACT_APP_API_URI + `material_datas/${data.material}` : null,
            initial_quantity: data.initial_quantity ? parseInt(data.initial_quantity) : null,
            empty_weight: parseInt(data.empty_weight)
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}

export const deleteResourceEmptyWeightData = async (dataId) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `resource_empty_weight_datas/${dataId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}

export const patchResourceEmptyWeightData = async (data) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `resource_empty_weight_datas/${data.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            brand: process.env.REACT_APP_API_URI + `resource_brand_datas/${data.brand}`,
            material: (data.material && parseInt(data.material) !== 0) ? process.env.REACT_APP_API_URI + `material_datas/${data.material}` : null,
            initial_quantity: data.initial_quantity ? parseInt(data.initial_quantity) : null,
            empty_weight: parseInt(data.empty_weight)
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    });
}
