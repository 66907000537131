import {MultiCascader, SelectPicker} from "rsuite";
import React, {useEffect, useState} from "react";
import {Loading} from "../common/Loading";
import {ResourceMaterialGroup} from "./ResourceMaterialGroup";
import {getSortedData} from "../../services/TablesService";
import {getCurrentUser, isUserGranted} from "../CommonFunctions";
import {AddResourceComponent} from "./right-panel/AddResource";
import {ResourceDataBlock} from "./ResourceDataBlock";
import {useGetData} from "../../api/useFetch";

export const ResourcesList = (props) => {

    const {brands, technologies, materials, colors, models, errorCallback, resourcesList, resources,
        successCallback, dataChange, setDataChange, refreshState, batchNumber, printers} = props;

    const checkedFiltersSections = ['brands', 'technologies', 'materials', 'colors', 'filament-diameters'];

    const [filtersList, setFiltersList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [checkedFilters, setCheckedFilters] = useState([]);
    const [resourcesListFilterData, setResourcesListFilterData] = useState([]);
    const [rightPartAddIsOpen, setRightPartAddIsOpen] = useState(false);
    // Resource data for add form if add form opened from table row click
    const [resourceDataToAdd, setResourceDataToAdd] = useState(null);

    const materialSorting = [
        {label: 'Material (A-Z)', value: 'material-asc'},
        {label: 'Material (Z-A)', value: 'material-desc'},
        {label: 'Technology (A-Z)', value: 'technology-asc'},
        {label: 'Technology (Z-A)', value: 'technology-desc'},
    ];

    // Open add form if batch number in url params
    useEffect(() => {
        if(batchNumber !== undefined) {
            setRightPartAddIsOpen(true);
        }
    }, [batchNumber]);

    useEffect(() => {
        setResourcesListFilterData(resourcesList);
    }, [resourcesList]);

    useEffect(() => {
        // inform tables resources list has changed
        setDataChange(dataChange + 1);
    }, [resourcesListFilterData]);

    /* Create filters data for simple section (with values id and name) */
    const createSectionFilters = (data, sectionName) => {
        let dataFilters = {
            label: sectionName.charAt(0).toUpperCase() + sectionName.slice(1),
            value: sectionName,
            children: []
        }
        data.map(item => {
            dataFilters.children.push({label: item.name, value: sectionName + '-' + item.id});
        });
        return dataFilters;
    }

    const createColorsFilters = (colorsData) => {
        let colorsFilters = {
            label: 'Colors',
            value: 'colors',
            children: []
        }
        colorsData.map(color => {
            colorsFilters.children.push({label: color.color_name, value: 'colors-' + color.id});
        });
        return colorsFilters;
    }

    const createFilamentDiametersFilters = () => {
        return {
            label: 'Filament diameters',
            value: 'filament-diameters',
            children: [
                {label: '1.75mm', value: 'filament-diameters-175'},
                {label: '2.85/3.00mm', value: 'filament-diameters-285-300'}
            ]
        }
    }

    useEffect(() => {
        // If all needed data available, create filters data
        if(brands.data !== undefined && technologies.data !== undefined && materials.data !== undefined && colors.data !== undefined) {
            setIsLoading(true);
            let newFiltersList = [];
            newFiltersList.push(createSectionFilters(brands.data, 'brands'));
            newFiltersList.push(createSectionFilters(technologies.data, 'technologies'));
            newFiltersList.push(createSectionFilters(materials.data, 'materials'));
            newFiltersList.push(createColorsFilters(colors.data));
            newFiltersList.push(createFilamentDiametersFilters());
            setFiltersList(newFiltersList);
            setIsLoading(false);
        }
    }, [brands, technologies, materials, colors]);

    /* Get checked filters for specific section with id value */
    const organizeCheckedFiltersWithId = (filtersSection) => {
        let filters = [];
        checkedFilters.map(checkedFilter => {
            if(checkedFilter.includes(filtersSection)) {
                filters.push(parseInt(checkedFilter.substring(filtersSection.length + 1)));
            }
        });
        return filters;
    }

    /* Get checked filters for specific section with text value */
    const organizeCheckedFiltersWithoutId = (filtersSection) => {
        let filters = [];
        checkedFilters.map(checkedFilter => {
            if(checkedFilter.includes(filtersSection)) {
                filters.push(checkedFilter);
            }
        });
        return filters;
    }

    /* Filter data */
    const filterResourcesListData = () => {
        if(checkedFilters.length === 0) {
            // Case no filter : get resourcesList data
            setResourcesListFilterData(resourcesList);
        } else {
            // Create a copy of resourcesList
            let data = resourcesList.map(obj => ({
                material: { ...obj.material }, // Copy material
                materialTypes: obj.materialTypes.map(materialType => ({
                    ...materialType, // Shallow copy materialType
                    children: materialType.children.map(child => ({
                        ...child, // Shallow copy child
                        quantity: { ...child.quantity } // Deep copy quantity object
                    }))
                }))
            }));
            // Filter
            data = filterResourcesListChildrenData(filterMaterialsData(data))
                .filter(item => item.materialTypes.length > 0);
            setResourcesListFilterData(data);
        }
    }

    /* Filter material types and resources data */
    const filterResourcesListChildrenData = (data) => {
        return data.map(material => {
            // Filter material types
            material.materialTypes = filterMaterialTypes(material.materialTypes);
            // Filter resources
            let materialTypes = material.materialTypes.map(materialType => {
                materialType.children = filterResources(materialType.children);
                if(materialType.children.length > 0) {
                    return materialType;
                }
            });
            material.materialTypes = materialTypes.filter(materialType => {
                return materialType !== undefined;
            });
            return material;
        });
    }

    /* Filter materials data (material - technology) */
    const filterMaterialsData = (materials) => {
        const materialsFilters = organizeCheckedFiltersWithId('materials');
        const technologiesFilters = organizeCheckedFiltersWithId('technologies');
        return materials.filter(material => {
            if(materialsFilters.length > 0) {
                if(checkIdInFiltersArray(material.material.id, materialsFilters)) {
                    if(technologiesFilters.length > 0) {
                        if(checkIdInFiltersArray(material.material.technology.id, technologiesFilters)) {
                            return material;
                        }
                    } else {
                        return material;
                    }
                }
            } else {
                if(technologiesFilters.length > 0) {
                    if(checkIdInFiltersArray(material.material.technology.id, technologiesFilters)) {
                        return material;
                    }
                } else {
                    return material;
                }
            }
        });
    }

    /* Filter material types (by brand and color) */
    const filterMaterialTypes = (materialTypes) => {
        const brandsFilters = organizeCheckedFiltersWithId('brands');
        const colorsFilters = organizeCheckedFiltersWithId('colors');
        return materialTypes.filter(item => {
            if(brandsFilters.length > 0) {
                if(checkIdInFiltersArray(item.brand.id, brandsFilters)) {
                    if(colorsFilters.length > 0) {
                        if(item.color && checkIdInFiltersArray(item.color.id, colorsFilters)) {
                            return item;
                        }
                    } else {
                        return item;
                    }
                }
            } else {
                if(colorsFilters.length > 0) {
                    if (item.color && checkIdInFiltersArray(item.color.id, colorsFilters)) {
                        return item;
                    }
                } else {
                    return item;
                }
            }
        });
    }

    /* Filter resources (filament diameter) */
    const filterResources = (resources) => {
        // Organize filters
        let filamentDiametersFilters = organizeCheckedFiltersWithoutId('filament-diameters');
        // Filter
        return resources.filter(item => {
            if(filamentDiametersFilters.length > 0) {
                if(checkFilamentDiameterFilters(item, filamentDiametersFilters)) {
                    return item;
                }
            } else {
                return item;
            }
        });
    }

    /* Check if filament diameter corresponds to filter */
    const checkFilamentDiameterFilters = (resource, filters) => {
        const diameter = resource.material.technology.has_diameter ? resource.material.technology.diameters[resource.diameter] : null;
        if(diameter === null) {
            return false;
        }
        for(let i = 0; i < filters.length; i++) {
            if((diameter.toString().includes('1.75') && filters[i].includes('175')) ||
                (diameter.includes('2.85') && diameter.includes('3.00') && filters[i].includes('285-300'))) {
                return true;
            }
        }
        return false;
    }

    const checkIdInFiltersArray = (id, filters) => {
        for(let i = 0; i < filters.length; i++) {
            if(id === filters[i]) {
                return true;
            }
        }
        return false;
    }

    const handleOnFiltersClean = () => {
        setCheckedFilters([]);
    }

    useEffect(() => {
        filterResourcesListData();
    }, [checkedFilters]);

    /* Get checked filters */
    const handleOnFiltersCheck = (value, item, checked) => {
        setCheckedFilters(value);
    }

    const handleSelectMaterialSorting = (value, item, event) => {
        // Get sorting infos
        const separatorPosition = value.indexOf('-');
        const dataToSort = value.substring(0, separatorPosition);
        const sortType = value.substring(separatorPosition + 1);
        // Prepare data to sorting
        let data = [];
        resourcesListFilterData.map(item => {
            data.push({data: item, material: item.material.name, technology: item.material.technology.name});
        });
        // Sorting
        data = getSortedData(data, dataToSort, sortType)
            .map(item => {
                return item.data;
        });
        setResourcesListFilterData(data);
    }

    /* Organization selection */
    const currentUser = getCurrentUser();

    let selectedOrganization = localStorage.getItem('selected-organization');

    if(selectedOrganization === undefined || selectedOrganization === null || selectedOrganization === 'null')
        selectedOrganization = currentUser.organization.id;
    else
        selectedOrganization = parseInt(selectedOrganization);

    /* Get all accessible organizations */
    const organizationGroup = useGetData('organizationGroup',
        'organization_groups/' + currentUser.organization.organization_group.id +
        '?groups[]=read:OrganizationGroup:Organizations'
    );

    if(isLoading || organizationGroup.isLoading) return <Loading/>

    const organizations = organizationGroup.data.organizations.map(organization => (
        {label: organization.name, value: organization.id}
    ));

    const handleSelectOrganization = (selectedOrganizationId) => {
        localStorage.setItem('selected-organization', JSON.stringify(selectedOrganizationId));
        window.location.reload();
    };

    return(
        <div className="main__resources_list">
            <ResourceDataBlock resources={resourcesListFilterData}/>
            <div className="resources-toolbar">
                <div className="resources-toolbar-left">
                    <div className="resources-filter-list">
                        <MultiCascader
                            data={filtersList}
                            value={checkedFilters}
                            uncheckableItemValues={checkedFiltersSections}
                            onCheck={(value, item, checked) => handleOnFiltersCheck(value, item, checked)}
                            onClean={() => handleOnFiltersClean()}
                            menuWidth={350} menuHeight={185} size={"md"} placeholder={'Filter...'}
                        />
                    </div>
                    <SelectPicker data={materialSorting} searchable={false} placeholder={'Sort by'}
                                  onSelect={(value, item, event) => handleSelectMaterialSorting(value, item, event)}
                    />
                    &nbsp;
                    <SelectPicker
                        size="md"
                        placeholder="Select an organization"
                        data={organizations}
                        value={selectedOrganization}
                        onChange={handleSelectOrganization}
                    />
                </div>
                {isUserGranted('ROLE_ADD_RESOURCE') &&
                <div className="resources-toolbar-right">
                    <button data-navigation="right" className="button-link" id="resources__add-resource"
                            onClick={() => setRightPartAddIsOpen(true)}>
                        <i className="fa fa-plus"/> Add a material
                    </button>
                    <AddResourceComponent
                        colors={colors} brands={brands} models={models} materials={materials} technologies={technologies}
                        successCallback={successCallback} errorCallback={errorCallback} batchNumber={batchNumber}
                        rightPartAddIsOpen={rightPartAddIsOpen} setRightPartAddIsOpen={setRightPartAddIsOpen}
                        resourceDataToAdd={resourceDataToAdd} setResourceDataToAdd={setResourceDataToAdd}
                    />
                </div>
                }
            </div>
            <div>
                {resourcesListFilterData.length === 0 &&
                    <p>No resource data</p>
                }
                {resourcesListFilterData.map(materialList => {
                    return <div key={materialList.material.id}>
                        <ResourceMaterialGroup
                            colors={colors} brands={brands} models={models} materials={materials}
                            technologies={technologies} printers={printers}
                            resources={resources} successCallback={successCallback} errorCallback={errorCallback}
                            materialList={materialList} dataChange={dataChange} refreshState={refreshState}
                            setRightPartAddIsOpen={setRightPartAddIsOpen} rightPartAddIsOpen={rightPartAddIsOpen}
                            setResourceDataToAdd={setResourceDataToAdd} resourceDataToAdd={resourceDataToAdd}
                        />
                    </div>
                })}
            </div>
        </div>
    );
}
