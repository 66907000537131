
const getTopLevelDomain = () => {
    const parts = process.env.REACT_APP_BASE_URL.split('.');
    return parts.length > 2 ? `.${parts.slice(-2).join('.')}` : `.${process.env.REACT_APP_BASE_URL}`;
};

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return null;
};

export const setCookie = (name, value, days) => {
    const expires = days ? `; expires=${new Date(Date.now() + days * 86400 * 1000).toUTCString()}` : '';
    document.cookie = `${name}=${value || ""}${expires}; path=/; domain=${getTopLevelDomain()}`;
};

export const deleteCookie = (name) => {
    document.cookie = `${name}=; path=/; domain=${getTopLevelDomain()}; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
};