import {
    getClassesDependingOnCellSize,
    getMaterialCellStatus, getMaterialStatusLabel,
    MATERIAL_CELL_STATUS
} from "../../../services/FarmService";

export const FarmCellMaterial = (props) => {

    const {cellSize, smartFarmCellData, cellContentClasses, farmCell} = props;

    const status = getMaterialCellStatus(smartFarmCellData);
    const statusLabel = getMaterialStatusLabel(status);

    return(
        <div className={cellContentClasses}>
            <div className="cell-header">
                <div className="cell-type">Materials</div>
            </div>
            {
                farmCell.has_equipments &&
                <div className="cell-body cell-material">
                    <div className="cell-material-content">
                        {status === MATERIAL_CELL_STATUS.REGENERATION &&
                            <div className={getClassesDependingOnCellSize(cellSize, 'material-status-block')}>
                                <div className="material-round">
                                    <div className={getClassesDependingOnCellSize(cellSize, 'round-spinner')}>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                                <div className={'material-status-value ' + getClassesDependingOnCellSize(cellSize, 'material-status-value-regeneration')}>
                                    <div>{statusLabel}</div>
                                </div>
                            </div>
                        }
                        {status === MATERIAL_CELL_STATUS.SWIRL &&
                            <div className={getClassesDependingOnCellSize(cellSize, 'material-status-block')}>
                                <div className={getClassesDependingOnCellSize(cellSize, 'material-round')}>
                                    <div className={getClassesDependingOnCellSize(cellSize, 'round-pulse')}></div>
                                </div>
                                <div className={'material-status-value ' + getClassesDependingOnCellSize(cellSize, 'material-status-value-swirl')}>
                                    <div>{statusLabel}</div>
                                </div>
                            </div>
                        }
                        {status === MATERIAL_CELL_STATUS.INACTIVE &&
                            <div className="material-status-value-inactive">
                                <div className={getClassesDependingOnCellSize(cellSize, 'material-status-value-inactive-value')}>
                                    {statusLabel}
                                </div>
                            </div>
                        }
                        {status === MATERIAL_CELL_STATUS.NO_DATA &&
                            <div className={getClassesDependingOnCellSize(cellSize, 'material-off')}><div>{statusLabel}</div></div>
                        }
                        <div className={getClassesDependingOnCellSize(cellSize, 'material-scale')}>
                            <button type="button" onClick={() => window.location = '/resources'}
                                    className={getClassesDependingOnCellSize(cellSize, 'material-scale-button')}
                            >
                                Weigh
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
