import React, {useEffect, useState} from "react";
import {QueryClient, QueryClientProvider} from 'react-query';
import {useGetData} from "../../api/useFetch";
import {getCurrentUser} from "../CommonFunctions";
import {AlertMessage} from "../common/AlertMessage";
import {ResourcesList} from "./ResourcesList";
import {useParams} from "react-router-dom";
import {EditRemainingQuantity} from "./EditRemainingQuantity";

const queryClient = new QueryClient();

export const Resources = () => {
    let user = getCurrentUser()

    const colors = useGetData('colors', 'resource_colors');
    const brands = useGetData('brands', 'resource_brands');
    const models = useGetData('models', 'models');
    const materials = useGetData('materials', 'materials');
    const technologies = useGetData('technologies', 'technologies');
    const resources = useGetData(
        `resourcesData`,
        'resource_items',
        {
            organization_group: user.organization.organization_group.id,
            archived: false
        }
    );
    const printers = useGetData(
        `printerData`,
        `printers/essentials?page=1&organization_group=${user.organization.organization_group.id}`
    );

    const [alert, setAlert] = useState();
    const [, refreshState] = useState();
    const [resourcesList, setResourcesList] = useState([]);
    const [dataChange, setDataChange] = useState(0);

    // Get batch number from url params
    let {batchNumber} = useParams();

    const getMaterialReferenceForLocalStorage = (resource) => {
        let resourceReference = 'reference_' + resource.material.id + '_' + resource.material.technology.id + resource.brand.id;
        if(resource.color) {
            resourceReference += '_' + resource.color.id;
        }
        return resourceReference;
    }

    useEffect(() => {
        if(resources.isLoading)
            return;

        let isFolded = localStorage.getItem('folded-resources');

        if(isFolded === undefined || isFolded === null)
            isFolded = {}
        else
            isFolded = JSON.parse(isFolded);

        for(const resource of resources.data){
            // First open only
            // Folded material groups
            const resourceMaterial = 'material_' + resource.material.id + '_' + resource.material.technology.id;
            if(!(resourceMaterial in isFolded))
                isFolded[resourceMaterial] = true;
            // Folded references
            let resourceReference = getMaterialReferenceForLocalStorage(resource);
            if(!(resourceReference in isFolded))
                isFolded[resourceReference] = true;
        }

        localStorage.setItem('folded-resources', JSON.stringify(isFolded));
    }, [resources.isLoading]);

    // Build resources list
    const buildResourcesListData = (resourcesData) => {
        let resourcesList = [];

        const currentUser = getCurrentUser();
        let selectedOrganization = localStorage.getItem('selected-organization');
        if(selectedOrganization === undefined || selectedOrganization === null || selectedOrganization === 'null')
            selectedOrganization = currentUser.organization.id;
        else
            selectedOrganization = parseInt(selectedOrganization);

        resourcesData.filter(resource => resource.organization.id === selectedOrganization).map(resource => {
            // Handle resource material
            let materialIndex = isMaterialInResourcesList(resource, resourcesList);
            if(materialIndex === -1) {
                resourcesList.push({material: resource.material, materialTypes: []});
                materialIndex = resourcesList.length - 1;
            }
            // Handle resource exact type (brand - color) depending on material
            let materialTypeIndex = isMaterialTypeInMaterialList(resource, resourcesList[materialIndex].materialTypes);
            if(materialTypeIndex === -1) {
                resourcesList[materialIndex].materialTypes.push(
                    {
                        id: materialTypeIndex === -1 ? ((resourcesList[materialIndex].materialTypes.length - 1) + '-0') : materialTypeIndex + '-0',
                        brand: resource.brand,
                        color: resource.color ? resource.color : null,
                        children: []
                    }
                );
                materialTypeIndex = resourcesList[materialIndex].materialTypes.length - 1;
            }
            resourcesList[materialIndex].materialTypes[materialTypeIndex].children.push(resource);
        });
        let formattedResourcesList = [];
        resourcesList.map(materialList => {
            formattedResourcesList.push(getFormattedMaterialList(materialList));
        });
        return formattedResourcesList;
    }

    /* Format data in resources list after building list */
    const getFormattedMaterialList = (materialList) => {
        materialList.materialTypes.map(type => {
            type.brand_color = type.brand.name + (type.color ? ' - ' + type.color.color_name : '');
            let remainingQuantity = 0;
            let initialQuantity = 0;
            let emptyWeight = 0;
            type.children.map(resource => {
                remainingQuantity += resource.remaining_quantity;
                initialQuantity += resource.initial_quantity;
                emptyWeight += resource.empty_weight;
                resource.quantity = <EditRemainingQuantity
                    resourceId={resource.id} remainingQuantity={resource.remaining_quantity}
                    updateRemainingQuantity={(quantity) => resource.quantity = quantity}
                    successCallback={successCallback} errorCallback={errorCallback}/>;
            });
            type.remaining_quantity = remainingQuantity;
            type.initial_quantity = initialQuantity;
            type.empty_weight = emptyWeight;
            type.quantity = type.children.length + ' (' + type.remaining_quantity + 'g)';
            type.children.map(resource => {
                resource.filamentDiameter =
                    resource.material.technology.has_diameter &&
                    `Ø ${resource.material.technology.diameters[resource.diameter]} mm`;
            });
        });
        return materialList;
    }

    /* Check if a material is already in the resources list - return material index or -1 */
    const isMaterialInResourcesList = (resource, resourcesList) => {
        for(let i = 0; i < resourcesList.length; i++) {
            if(resourcesList[i].material.id === resource.material.id) {
                return i;
            }
        }
        return -1;
    }

    /* Check if a material type (exact material : brand - color) is already in the resources list
    return materialType index or -1 */
    const isMaterialTypeInMaterialList = (resource, materialTypesList) => {
        for(let i = 0; i < materialTypesList.length; i++) {
            // Brand - color - filament diameter comparisons
            if(materialTypesList[i].brand && materialTypesList[i].brand.id === resource.brand.id &&
                ((materialTypesList[i].color && resource.color && materialTypesList[i].color.id === resource.color.id)
                    || (!materialTypesList[i].color && !resource.color))) {
                return i;
            }
        }
        return -1;
    }

    useEffect(() => {
        if(resources.data !== undefined) {
            // Build resources list for display
            setResourcesList(buildResourcesListData(resources.data));
        }
    }, [resources.isLoading]);

    const successCallback = (message) => {
        resources.refetch().then((data) => {
            resources.refetch().then(data => {
                // Force set response data
                resources.data = data.data;
                setAlert({message: message, status: "success", date: new Date()});
                refreshState({});
                // Rebuild resources list
                setResourcesList(buildResourcesListData(resources.data));
                setDataChange(dataChange + 1);
            });
            printers.refetch();
        });
        // Handle batch number in url params
        if(batchNumber !== undefined) {
            window.history.pushState({}, "resources", "/resources");
            batchNumber = undefined;
        }
    }

    const errorCallback = (message) => {
        resources.refetch().then(() => {
            setAlert({message: message, status: "error", date: new Date()});
            refreshState({});
        });
    };
    
    return (
        <div id="main__resources">
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}
            <QueryClientProvider client={queryClient}>
                <ResourcesList
                    colors={colors} brands={brands} materials={materials} technologies={technologies} models={models}
                    resources={resources} successCallback={successCallback} errorCallback={errorCallback}
                    dataChange={dataChange} setDataChange={setDataChange} refreshState={refreshState}
                    batchNumber={batchNumber} printers={printers} resourcesList={resourcesList}
                />
            </QueryClientProvider>

        </div>
    )
}
