import {OrganizationBlock} from "./OrganizationBlock";
import {WeatherBlock} from "./WeatherBlock";
import {MicroFactoryBlock} from "./MicroFactoryBlock";
import {useSmartFarmData} from "../../../contexts/SmartFarmDataContext";

export const FarmHeader = (props) => {

    const {selectedOrganization, setSelectedOrganization, farmVersion, externalSystemCode} = props;

    const {smartFarmData} = useSmartFarmData();

    return(
      <div className="farm__header">
          <OrganizationBlock
              selectedOrganization={selectedOrganization} setSelectedOrganization={setSelectedOrganization}
          />
          <MicroFactoryBlock
              selectedOrganization={selectedOrganization} farmVersion={farmVersion} externalSystemCode={externalSystemCode}
              smartFarmData={smartFarmData}
          />
          <WeatherBlock selectedOrganization={selectedOrganization}/>
      </div>
    );
}
