import React from "react";
import {ChartsDataRangeBlock} from "./blocks/ChartsDataRangeBlock";
import {
    createCsvData,
    downloadCsvData, getInfluxdbEnvironmentDataFieldsWithoutPollution,
    mergeEnvironmentDataLists
} from "../../../services/FarmService";
import {HumidityBlock} from "./blocks/HumidityBlock";
import {TemperatureBlock} from "./blocks/TemperatureBlock";
import {Loading} from "../../common/Loading";
import {MaterialIcon} from "../../common/icons/MaterialIcon";
import {MaintenanceBlock} from "./blocks/MaintenanceBlock";
import {useSmartFarmData} from "../../../contexts/SmartFarmDataContext";
import {PollutionBlock} from "./blocks/PollutionBlock";

export const FarmDialog = (props) => {

    const {setAlert, farmVersion, children, render, systemCode, isCellPanel, setFarmMaintenances} = props;

    const {smartFarmIsReady, receivingData, smartFarmDataHistory, selectedDataRange, setSelectedDataRange, setCustomDataRange} = useSmartFarmData();

    const influxdbFieldsWithoutPollution = getInfluxdbEnvironmentDataFieldsWithoutPollution(isCellPanel, farmVersion);

    /* Download environment data as csv file */
    const handleDownloadEnvironmentData = () => {
        Promise.resolve(mergeEnvironmentDataLists(
            structuredClone(smartFarmDataHistory[systemCode].temperature),
            structuredClone(smartFarmDataHistory[systemCode].humidity)
        )).then(formattedData => {
            const csvData = createCsvData(formattedData);
            downloadCsvData(csvData)
                .then(() => setAlert({date: new Date(), message: 'Environment data file created', status: 'success'}))
                .catch(() => setAlert({date: new Date(), message: 'An error occurred while creating csv', status: 'error'}));
        });
    }

    const getChartsDataRangeBlock = () => {
        return (
            <ChartsDataRangeBlock
                selectedDataRange={selectedDataRange} setSelectedDataRange={setSelectedDataRange}
                setCustomDataRange={setCustomDataRange} handleDownloadEnvironmentData={() => handleDownloadEnvironmentData(influxdbFieldsWithoutPollution.tvoc)}
            />
        )
    }

    const getTemperatureBlock = (farmEvents, isCommandEnabled, structureMaintenance, cellMaintenance, handleTemperatureChange = undefined, ) => {
        return(
            <TemperatureBlock
                systemCode={systemCode} farmEvents={farmEvents}
                handleTemperatureChange={handleTemperatureChange} isCommandEnabled={isCommandEnabled}
                structureMaintenance={structureMaintenance} cellMaintenance={cellMaintenance}
            />
        );
    }

    const getHumidityBlock = () => {
        return(
            <HumidityBlock systemCode={systemCode}/>
        );
    }

    const getPollutionBlock = (isCommandEnabled, structureMaintenance, cellMaintenance, airExtractionCommandData = undefined,
                               handleExtractionChange = undefined, filterChange = undefined, farmCell = undefined) => {
        return(
            <PollutionBlock
                pollutionList={null} tvocList={null} airExtractionCommandData={airExtractionCommandData}
                handleExtractionChange={handleExtractionChange} vocType={influxdbFieldsWithoutPollution.tvoc} filterChange={filterChange}
                selectedPollutionType={null} setSelectedPollutionType={null}
                isCommandEnabled={isCommandEnabled} systemCode={systemCode}
                structureMaintenance={structureMaintenance} cellMaintenance={cellMaintenance}
                farmCell={farmCell}
            />
        );
    }

    const getMaintenanceBlock = (structureMaintenance, cellMaintenance, structureId, cellId, smartFarmDataMaintenance, farmCell) => {
        return(
            <MaintenanceBlock
                structureMaintenance={structureMaintenance} cellMaintenance={cellMaintenance}
                structureId={structureId} cellId={cellId} systemCode={systemCode} setAlert={setAlert}
                setFarmMaintenances={setFarmMaintenances} smartFarmDataMaintenance={smartFarmDataMaintenance}
                farmCell={farmCell}
            />
        );
    }

    return (
        <div className="farm__dialog">
            <div className="farm-dialog-title-bloc flex">
                <div className="farm-dialog-title">{isCellPanel ? 'Settings' : 'General settings'}
                {
                    isCellPanel && <span className="environment-name">[{systemCode}]</span>
                }
                </div>
            </div>
            {!smartFarmIsReady ?
                <Loading/> :
                <>
                    {/* Warning if no connexion with smart farm */}
                    {!receivingData &&
                        <div className="connection-smart-farm-error">
                            <MaterialIcon classes="smart-farm-error-icon" label="error"/>
                            <span className="smart-farm-error-text">Connection with Smart Farm not available</span>
                        </div>
                    }
                    {render(children, {
                        getChartsDataRangeBlock, getTemperatureBlock, getHumidityBlock, getPollutionBlock, getMaintenanceBlock
                    })}
                </>
            }
        </div>
    );
}
