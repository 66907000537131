import React, {useEffect, useRef, useState} from 'react';
import {ALERT_STATUS, getAlertContent, getCurrentTimestamp, getCurrentUser} from "../../CommonFunctions";
import {
    validateStartTask,
    validateEndTask,
    patchTaskInterruptionNotes,
    patchTaskUpdateControllablePrinterTaskId, postTaskInterruption, patchTaskInterruption
} from "../../../api/apiGantt";
import {Checkbox} from "@material-ui/core";
import {DigitCode} from "../../common/DigitCode";
import Modal from "../../common/Modal";
import {fetchData} from "../../../api/useFetch";
import {Toggle} from "rsuite";
import {ControllablePrinterModal} from "../ControllablePrinterModal";
import {activateFarmProfile, SMART_PRINTER_STATUS, stopFarmProfile} from "../../../services/FarmService";
import {MaterialIcon} from "../../common/icons/MaterialIcon";
import {postStartPrint} from "../../../api/apiConnectivity";
import {
    getStartPrintErrorModalDataByErrorCode,
    isPrinterWorking
} from "../../../services/automation/ConnectivityService";
import {getSocketStartPrintObservable, resetSocketStartPrintSubject} from "../../../services/socketEventsService";
import {PRINTER_BRANDS} from "../../../services/automation/AutomationService";

export const TaskActionsPrinting = (props) => {

    const {task, successCallback, errorCallback, setIsTaskLoading, controllablePrinter,
        setAlert, getControllablePrinter} = props;

    // task printer brand code
    const brandCode = task.data.printer.brand.printer_brand_data ? task.data.printer.brand.printer_brand_data.code : null;

    const isControllablePrinterStartPrintAvailable = task.data.printer.brand.printer_brand_data ?
        task.data.printer.brand.printer_brand_data.has_api_start_print_on_printer : false;

    /* Validate that task has a build if builds are available on printer brand */
    const isControllablePrinterBuildDataValid = () => {
        if(task.data.printer.brand && task.data.printer.brand.printer_brand_data) {
            return !task.data.printer.brand.printer_brand_data.has_api_get_single_build ||
                (task.data.pic.slicer.build_id !== undefined && task.data.pic.slicer.build_id !== null)
        }
        return true;
    }

    // Controllable printer modal buttons
    const validateStartPrintingButton = {label: 'Validate printing start', callback: () => {
            submitValidateStartTask(getCurrentUser());
            setIsControllablePrinterModalOpen(false);
        }};
    const switchToManualModeButton = {label: 'Set Manual Mode', callback: () => {
            setIsManualMode(true);
            setIsControllablePrinterModalOpen(false);
            setIsTaskLoading(false);
        }};
    const goToPicBuilderButton = {label: 'Go to PIC Builder', callback: () => {
            window.location = '/builder/' + task.data.pic.id;
            setIsControllablePrinterModalOpen(false);
        }};

    const [digitCodeStartVisible, setDigitCodeStartVisible] = useState(false);
    const [digitCodeEndVisible, setDigitCodeEndVisible] = useState(false);
    const [digitCodeInterruptionVisible, setDigitCodeInterruptionVisible] = useState(false);
    const [digitCodeErrorVisible, setDigitCodeErrorVisible] = useState(false);
    const [showAdjustStartModal, setShowAdjustStartModal] = useState(false);
    const [showAdjustEndModal, setShowAdjustEndModal] = useState(false);
    const [adjustStartHour, setAdjustStartHour] = useState(false);
    const [adjustEndHour, setAdjustEndHour] = useState(false);
    const [_state, refreshState] = useState(true);

    // States for controllable printer print errors
    const [isControllablePrinterModalOpen, setIsControllablePrinterModalOpen] = useState(false);
    const [controllablePrinterModalDescription, setControllablePrinterModalDescription] = useState('Print error');
    const [controllablePrinterModalTitle, setControllablePrinterModalTitle] = useState('An error occurred');
    const [controllablePrinterModalButtons, setControllablePrinterModalButtons] = useState([]);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [isModalCancelButton, setIsModalCancelButton] = useState(true);

    const [startControllablePrintEvent, setStartControllablePrintEvent] = useState(null);
    const [printStartUser, setPrintStartUser] = useState(null);
    const [isWaitingForPrintStartEvent, setIsWaitingForPrintStartEvent] = useState(false);

    const isWaitingForPrintStartEventTimeout = useRef();

    const [isManualMode, setIsManualMode] = useState(false);

    useEffect(() => {
        // Get controllable printer if task's printer is controllable printer
        if(task.data.printer.controllable_printer_id) {
            getControllablePrinter();
        }
        // Subscribe to start print events (used to check if print started)
        const startPrintSubscription = getSocketStartPrintObservable().subscribe({
            next: (startPrintData) => {
                setStartControllablePrintEvent(startPrintData);
            }
        });

        // Reset controllable printer states on component destroy
        return(() => {
           setIsManualMode(false);
           setIsControllablePrinterModalOpen(false);
           setPrintStartUser(null);
           setStartControllablePrintEvent(null);
           startPrintSubscription.unsubscribe();
        });
    }, []);

    // Get controllable printer data every time manual mode is switched off
    useEffect(() => {
        if(!isManualMode && task.data.printer.controllable_printer_id) {
            getControllablePrinter();
        }
    }, [isManualMode]);

    useEffect(() => {
        // Add timeout while waiting for start print event, if no event in 90 seconds stop waiting for event and display error modal
        if(isWaitingForPrintStartEvent) {
            if(isWaitingForPrintStartEventTimeout.current) {
                clearTimeout(isWaitingForPrintStartEventTimeout.current);
            }
            isWaitingForPrintStartEventTimeout.current = setTimeout(() => {
                openControllablePrinterErrorModal(0, 'No response from the service.');
                setIsWaitingForPrintStartEvent(false);
            }, 90000); // 90 seconds
        } else {
            clearTimeout(isWaitingForPrintStartEventTimeout.current);
        }
    }, [isWaitingForPrintStartEvent]);

    /* Start print (Step 3.1) - only for start print with controllable printer start print request: wait for start print event */
    useEffect(() => {
        // If there is a start print event and displayed task corresponds to controllable printer in event, handle start print status
        if(isWaitingForPrintStartEvent && startControllablePrintEvent && task.data.printer.controllable_printer_id /*&&
            startControllablePrintEvent.controllable_printer_id === task.data.printer.controllable_printer_id*/) {
            if(startControllablePrintEvent.status === 'success') {
                // Save controllable printer print task id
                if(startControllablePrintEvent.task_id) {
                    savePrintTaskIdFromControllablePrinter(startControllablePrintEvent.task_id); // (Step 3.2)
                }
                // Validate print start
                if(printStartUser) {
                    submitValidateStartTask(printStartUser)
                        .then(() => handleControllablePrinterModalClose())
                        .catch(() => null);
                }
                // Reset start print data
                setPrintStartUser(null);
                resetSocketStartPrintSubject();
            } else if(startControllablePrintEvent.status === 'partial success') {
                // If unable to check if print started, show error modal
                openControllablePrinterErrorModal(410);
            } else {
                // If error, show error modal
                openControllablePrinterErrorModal(startControllablePrintEvent.code, startControllablePrintEvent.message);
            }
        }
        if(startControllablePrintEvent) {
            setIsWaitingForPrintStartEvent(false);
        }
    }, [startControllablePrintEvent]);

    // START PRINT

    /* Start print (Step 1): if controllable printer, check build exists before starting print */
    const handleStartPrintButtonClick = async () => {
        // If printer is connected to controllable printer, check printer before start printing
        if(controllablePrinter !== undefined && controllablePrinter !== null && !isManualMode && isControllablePrinterStartPrintAvailable) {
            if(!isControllablePrinterBuildDataValid()) {
                // Handle no build data
                handleControllablePrinterModalOpen('Build error',
                    'There is no build defined in your pic, please select a build in the PIC builder',
                    [goToPicBuilderButton, switchToManualModeButton]);
            } else {
                handleStartPrintingNextStepDependingOnTaskDate();
            }
        } else {
            handleStartPrintingNextStepDependingOnTaskDate();
        }
    };

    /* Start print (Step 2): if task start date does not correspond to current date (+/- 5 minutes), show adjust time modal, else: display digit code */
    const handleStartPrintingNextStepDependingOnTaskDate = () => {
        // Raise alert if more than 5 minutes
        if(!adjustStartHour && Math.abs(task.data.date - getCurrentTimestamp()) > 5 * 60)
            setShowAdjustStartModal(true);
        else
            setDigitCodeStartVisible(true);
    }

    /* Start print (Step 3): start printing after digit code validation */
    const handleStartPrintAfterDigitCodeValidation = async (user) => {
        setPrintStartUser(user);
        setIsTaskLoading(true);
        // If controllable printer, send print to printer
        if(controllablePrinter !== undefined && controllablePrinter !== null && !isManualMode &&
            isControllablePrinterStartPrintAvailable) {
            const buildId = task.data.pic && task.data.pic.slicer.build_id ? task.data.pic.slicer.build_id : null;

            postStartPrint(controllablePrinter.id_manufacturer, brandCode, buildId)
                .then(response => {
                    if(response.ok) {
                        setIsWaitingForPrintStartEvent(true);
                        // Open loading modal and wait for print start event (received in useEffect - Step 3.1)
                        handleControllablePrinterModalOpen(
                            'Starting printing',
                            'Waiting for printer to start...',
                            [],
                            true,
                            false
                        );
                    } else {
                        // Open error modal if response error
                        openControllablePrinterErrorModal(0);
                    }
                })
                .catch(() => openControllablePrinterErrorModal(0));

        } else {
            // Printer is not connected to controllable printer : validate print start
            await submitValidateStartTask(user);
        }
    }

    /* Start print (Step 3.2): Only for start print with controllable printer start print action:
       update task to save print task id from controllable printer (called in useEffect in step 3.1) */
    const savePrintTaskIdFromControllablePrinter = (printTaskId) => {
        patchTaskUpdateControllablePrinterTaskId(task.data.id, printTaskId)
            .then()
            .catch(() => errorCallback('Error while saving data from connected printer'));
    }

    /* Start print (Step 4): save print start validation in DB and then handle task farm profile */
    const submitValidateStartTask = async (user) => {
        return await validateStartTask(task, task.data.printer, adjustStartHour, user)
            .then(() => {
                task.data.start_validated = true;
                successCallback('The start of the job has been validated.');

                const startOpenLink = localStorage.getItem('start-open-link');

                if(startOpenLink !== undefined && startOpenLink !== null && startOpenLink !== 'null')
                    window.open(startOpenLink, '_blank').focus();

                // If task is linked to a farm profile, activate farm profile
                activateFarmAutomationOnTaskStart(task.data);
            })
            .catch(() => {
                errorCallback('Something went wrong.');
            })
            .finally(() => refreshState(!_state));
    }

    /* Start print (Step 5): Execute automation actions (led color - farm profile) */
    const activateFarmAutomationOnTaskStart = (task) => {
        if(task && task.printer && task.printer.farm_cell && task.printer.farm_cell.id && task.printer.farm_cell.system_code) {
            // Check if there is a profile to execute and execute it
            if (task.pic && task.pic.slicer && task.pic.slicer.farm_profile && task.pic.slicer.farm_profile.id) {
                // Get farm profile to execute
                fetchData('farm_profiles/' + task.pic.slicer.farm_profile.id)
                    .then(response => response.json())
                    .catch(() => setAlert(getAlertContent('Farm profile could not be executed', ALERT_STATUS.ERROR)))
                    // Execute profile
                    .then(profile => {
                        activateFarmProfile(profile, task.printer.farm_cell.system_code, task.printer.farm_cell.id, true)
                            .then(() => setAlert(getAlertContent('Farm profile executed', ALERT_STATUS.SUCCESS)))
                            .catch(() => setAlert(getAlertContent('Farm profile could not be executed', ALERT_STATUS.ERROR)));
                    })
                    .catch(() => setAlert(getAlertContent('Farm profile could not be executed', ALERT_STATUS.ERROR)));
            }
        }
    }

    // Adjust time modal actions

    /* Handle adjust start time modal action */
    const handleAdjustStartTimeModalAction = (adjust) => {
        setAdjustStartHour(adjust);
        setShowAdjustStartModal(false);
        setDigitCodeStartVisible(true);
    };

    /* Handle adjust end time modal action */
    const handleAdjustEndTimeModalAction = (adjust) => {
        setAdjustEndHour(adjust);
        setShowAdjustEndModal(false);
        setDigitCodeEndVisible(true);
    };

    // END PRINT

    /* End print (Step 1): handle end print button click - display adjust time modal or digit code depending on task end date */
    const handleEndPrintingButtonClick = () => {
        // Raise alert if more than 5 minutes (and not a detected print)
        if((task.data.start_date_detected === undefined || task.data.start_date_detected === null) &&
            (!adjustEndHour && Math.abs(task.data.end_date - getCurrentTimestamp()) > 5 * 60))
            setShowAdjustEndModal(true);
        else
            setDigitCodeEndVisible(true);
    }

    /* End print (Step 2): after digit code validation, save end task validation in DB and then stop farm automation */
    const handleValidateEndFormSubmit = async (user) => {
        setIsTaskLoading(true);
        await validateEndTask(task, adjustEndHour, false, user)
            .then(() => {
                // Stop automation (led color - farm profile)
                stopFarmAutomationOnTaskStop(task.data);
                successCallback('The end of the job has been validated.');
                refreshState(!_state);
            })
            .catch(() =>{
                errorCallback('Something went wrong.');
                refreshState(!_state);
            })
    }

    /* End print (Step 3): Stop farm automation (led color - any farm profile running) */
    const stopFarmAutomationOnTaskStop = (task) => {
        if (task && task.printer && task.printer.farm_cell && task.printer.farm_cell.id && task.printer.farm_cell.system_code) {
            let promises = [];
            promises.push(stopFarmProfile(task.printer.farm_cell.system_code, task.printer.farm_cell.id, true));
            Promise.all(promises)
                .then(() => setAlert({message: 'Farm profile stopped', status: 'success', date: new Date()}))
                .catch(() => setAlert({message: 'Farm automation could not be stopped correctly', status: 'error', date: new Date()}));
        }
    }

    // Print interruption

    /* Print interruption - after digit code validation: save interruption in DB and handle controllable printer prints */
    const handleTaskInterruption = async (user) => {
        setIsTaskLoading(true);
        if(controllablePrinter !== null && task.data.printer.brand.printer_brand_data &&
            task.data.printer.brand.printer_brand_data.code === PRINTER_BRANDS.MARKFORGED.code) {
            window.open('https://www.eiger.io/device/' + controllablePrinter.id_manufacturer, '_blank');
        }
        await postTaskInterruption(task, user)
            .then(() => {
                successCallback('The job has been interrupted.');
                refreshState(!_state);
            })
            .catch(() => {
                errorCallback('Something went wrong.');
                refreshState(!_state);
            });
    }

    /* Save task interruption note written by user */
    const handleTaskInterruptionNote = (note) => {
        patchTaskInterruptionNotes(task.data.current_interruption.id, note)
            .then(() => {
                 successCallback('Notes have been updated.');
            })
            .catch(() => {
                errorCallback('Something went wrong.');
            });
    };

    /* Restart print after print interruption */
    const handleTaskInterruptionResume = () => {
        setIsTaskLoading(true);
        patchTaskInterruption(task.data.id, task.data.current_interruption.id)
            .then(() => {
                successCallback('The job started again.');
                refreshState(!_state);
            })
            .catch(() => {
                errorCallback('Something went wrong.');
                refreshState(!_state);
            });
    }

    // Print error

    /* Print error - after digit code validation: save interruption in DB and handle controllable printer prints */
    const handleTaskError = async (user) => {
        setIsTaskLoading(true);
        if(controllablePrinter !== null && task.data.printer.brand.printer_brand_data &&
            task.data.printer.brand.printer_brand_data.code === PRINTER_BRANDS.MARKFORGED.code) {
            window.open('https://www.eiger.io/device/' + controllablePrinter.id_manufacturer, '_blank');
        }
        await validateEndTask(task, true, true, user)
            .then(() => {
                // Stop automation (led color - farm profile)
                stopFarmAutomationOnTaskStop(task.data);
                successCallback('The job has been stopped.');
                refreshState(!_state);
            })
            .catch(() =>{
                setAlert({message: 'Farm profile could not be stopped correctly', status: 'error', date: new Date()});
                refreshState(!_state);
            });
    }

    // Controllable printer modal

    /* Get modal data and open controllable printer modal */
    const openControllablePrinterErrorModal = (errorCode, errorMessage = null) => {
        const modalData = getStartPrintErrorModalDataByErrorCode(errorCode, goToPicBuilderButton, switchToManualModeButton, validateStartPrintingButton);
        const description = errorMessage !== null ? modalData.description + ' (Details: ' + errorMessage + ')' : modalData.description;
        handleControllablePrinterModalOpen(modalData.title, description, modalData.buttons);
    }

    /* Handle ControllablePrinterModal Opening */
    const handleControllablePrinterModalOpen = (title = 'Print error', description = 'An error occurred',
                                                buttons = [], loading = false, isCancelButton = true) => {
        setControllablePrinterModalTitle(title);
        setControllablePrinterModalDescription(description);
        setControllablePrinterModalButtons(buttons);
        setIsControllablePrinterModalOpen(true);
        setIsModalLoading(loading);
        setIsModalCancelButton(isCancelButton);
    }

    /* Handle ControllablePrinterModal Closing */
    const handleControllablePrinterModalClose = () => {
        setIsControllablePrinterModalOpen(false);
        setControllablePrinterModalTitle('Print error');
        setControllablePrinterModalDescription('An error occurred');
        setControllablePrinterModalButtons([]);
        setIsModalLoading(false);
        setIsTaskLoading(false);
        setPrintStartUser(null);
        setStartControllablePrintEvent(null);
        getControllablePrinter();
    }

    return(
        <div>
            <DigitCode visible={digitCodeStartVisible} setVisible={setDigitCodeStartVisible} callback={handleStartPrintAfterDigitCodeValidation} errorCallback={errorCallback}/>
            <DigitCode visible={digitCodeEndVisible} setVisible={setDigitCodeEndVisible} callback={handleValidateEndFormSubmit} errorCallback={errorCallback}/>
            <DigitCode visible={digitCodeInterruptionVisible} setVisible={setDigitCodeInterruptionVisible} callback={handleTaskInterruption} errorCallback={errorCallback}/>
            <DigitCode visible={digitCodeErrorVisible} setVisible={setDigitCodeErrorVisible} callback={handleTaskError} errorCallback={errorCallback}/>

            {showAdjustStartModal &&
                <Modal
                    title="Adjust start time"
                    description={'Do you want to adjust the start time of the print?'}
                    buttons={[
                        {label: 'Yes', callback: () => handleAdjustStartTimeModalAction(true)},
                        {label: 'No', callback: () => handleAdjustStartTimeModalAction(false)}
                    ]}
                    cancelCallback={() => setShowAdjustStartModal(false)}
                />
            }

            {showAdjustEndModal &&
                <Modal
                    title="Adjust end time"
                    description={'Do you want to adjust the end time of the print?'}
                    buttons={[
                        {label: 'Yes', callback: () => handleAdjustEndTimeModalAction(true)},
                        {label: 'No', callback: () => handleAdjustEndTimeModalAction(false)}
                    ]}
                    cancelCallback={() => setShowAdjustEndModal(false)}
                />
            }

            {isControllablePrinterModalOpen &&
                <ControllablePrinterModal
                    title={controllablePrinterModalTitle}
                    description={controllablePrinterModalDescription}
                    buttons={controllablePrinterModalButtons}
                    cancelCallback={() => handleControllablePrinterModalClose()}
                    loading={isModalLoading}
                    isCancelButton={isModalCancelButton}
                />
            }

            {!task.data.start_validated ?
                <form id="validate-start-of-task__form">
                    {task.data.printer.controllable_printer_id &&
                        <div>
                            <p className="connected-printer-interaction">Connected printer interaction:</p>
                            <div className="toggle-mode-block">
                                <span>Handddle App only</span>
                                <Toggle className="toggle-mode" checked={!isManualMode} onChange={() => setIsManualMode(!isManualMode)}/>
                                <span>Connected</span>
                            </div>
                        </div>
                    }

                    {(controllablePrinter !== null && controllablePrinter.state !== SMART_PRINTER_STATUS.OFF.id && !isManualMode) &&
                        <div className="connected-printer-messages">
                            <p className="connected-printer-message-success">
                                <i className='fa fa-check-circle'/> Printer connected
                            </p>
                            <p className="connected-printer-message-warning">
                                <i className='fa fa-exclamation-triangle'/> Always check if printer is ready before launch
                            </p>
                            {isPrinterWorking(controllablePrinter.state) &&
                                <p className="connected-printer-message-error">
                                    <i className='fa fa-exclamation-triangle'/> Printer already printing
                                </p>
                            }
                        </div>
                    }
                    {(controllablePrinter !== null && controllablePrinter.state === SMART_PRINTER_STATUS.OFF.id && !isManualMode) &&
                        <div className="connected-printer-messages">
                            <p className="connected-printer-message-error">
                                <i className='fa fa-exclamation-triangle'/> Printer offline
                            </p>
                        </div>
                    }
                    {(controllablePrinter === null && task.data.printer.controllable_printer_id && !isManualMode) &&
                        <p className="connected-printer-message-error">
                            <i className='fa fa-exclamation-triangle'/> Manual mode: connection with printer failed
                        </p>
                    }
                    {isManualMode &&
                        <p className="connected-printer-message-warning">
                            <i className='fa fa-exclamation-triangle'/> Printer won't launch automatically
                        </p>
                    }

                    <div className="action-button-zone">
                        <div>
                            <Checkbox checked={adjustStartHour} onClick={() => setAdjustStartHour(!adjustStartHour)}/> Adjust start hour
                            <button className="button-success" onClick={(e) => {
                                    e.preventDefault();
                                    handleStartPrintButtonClick();
                                }}
                                style={{marginRight: '4px'}}
                                disabled={
                                    !(((controllablePrinter === null || (controllablePrinter.state !== isPrinterWorking(controllablePrinter.state)
                                            && controllablePrinter.state !== SMART_PRINTER_STATUS.OFF.id)) || isManualMode))
                                }>
                                <MaterialIcon label="not_started"/>
                                Launch
                            </button>
                        </div>
                    </div>
                </form>
            :
                task.data.date < getCurrentTimestamp() &&
                    <>
                        <div className="action-button-zone">
                            {
                                task.data.is_error ?
                                    <h3>Stopped</h3>
                            :
                                task.data.current_interruption ?
                                    <button className="button-failed" onClick={(e) => {
                                        e.preventDefault();
                                        handleTaskInterruptionResume();
                                    }}>Resume</button>
                            : // Default actions
                                    <>
                                        <button id="task__validate-end" className="button-success" onClick={(e) => {
                                            e.preventDefault();
                                            handleEndPrintingButtonClick();
                                        }}><MaterialIcon label="thumb_up"/>Print success</button>
                                        <button className="button-warning" onClick={(e) => {
                                            e.preventDefault();
                                            setDigitCodeInterruptionVisible(true);
                                        }}><MaterialIcon label="pause_circle"/>Interruption</button>
                                        <button className="button-error" onClick={(e) => {
                                            e.preventDefault();
                                            setDigitCodeErrorVisible(true);
                                        }}><MaterialIcon label="thumb_down"/>Print error</button>
                                    </>
                            }

                        </div>
                        {task.data.current_interruption &&
                            <div id="task__pause-note">
                                <h3>Interruption notes</h3>
                                <textarea
                                    placeholder="Write something about it"
                                    onBlur={(e) => {
                                        handleTaskInterruptionNote(e.target.value)
                                    }}
                                >
                                    {task.data.current_interruption.note}
                                </textarea>
                            </div>
                        }
                    </>
            }

            {(controllablePrinter !== undefined && controllablePrinter !== null
                && task.data.pic && task.data.pic.slicer && task.data.pic.slicer.build_id !== undefined && task.data.pic.slicer.build_id !== null
                && task.data.printer.brand.printer_brand_data && task.data.printer.brand.printer_brand_data.code === PRINTER_BRANDS.MARKFORGED.code) &&
                    <button className="button button-build-page" onClick={() => {
                        window.open('https://www.eiger.io/prints?print=' + task.data.pic.slicer.build_id, '_blank');
                    }}>
                        Go to build page
                    </button>
            }
        </div>
    )
}
