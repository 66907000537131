import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import './css/theme/custom-rsuite-theme.css';
import "./css/common/main.css";
import "./css/dashboard/dashboard.css";
import "./css/planning/planning.css";
import "./css/farm/farm.css";
import "./css/library/library.css";
import "./css/projects/projects.css";
import "./css/profile/profile.css";
import "./css/pic/pic.css";
import "./css/pic/pic.js";
import "./css/printers/printers.css";
import 'react-tippy/dist/tippy.css';
import {MainMenu} from "./components/MainMenu";
import {LoginComponent} from "./components/login/Login";
import {DashboardMain} from "./components/DashboardMain";
import {GanttMain} from "./components/GanttMain";
import {PrintersMain} from "./components/PrintersMain";
import {ResourcesMain} from "./components/ResourcesMain";
import {FarmMain} from "./components/FarmMain";
import {LibraryMain} from "./components/LibraryMain";
import {ProjectsMain} from "./components/ProjectsMain";
import {ProjectMain} from "./components/ProjectMain";
import {ProfileMain} from "./components/ProfileMain";
import {OrganizationMain} from "./components/OrganizationMain";
import {MembersMain} from "./components/MembersMain";
import {PicBuilderMain} from "./components/PicBuilderMain";
import {PicManagerMain} from "./components/PicManagerMain";
import {Register} from "./components/register/Register";
import '@fortawesome/fontawesome';
import * as Icons from '@fortawesome/fontawesome-free-solid';
import jwt_decode  from 'jwt-decode'
import {HeaderContent} from "./components/common/HeaderContent";
import {QueryClient, QueryClientProvider} from 'react-query';
import {Confirm} from "./components/register/Confirm";
import {HistoryMain} from "./components/HistoryMain";
import {createMuiTheme, ThemeProvider} from '@material-ui/core';
import {BackofficeMain} from "./components/BackofficeMain";
import {ConnectivityMain} from "./components/ConnectivityMain";
import {fetchData} from "./api/useFetch";
import {disconnectUser, getCurrentUser, isMinimalApp, setCurrentUserInLocalStorage} from "./components/CommonFunctions";
import {Loading} from "./components/common/Loading";
import {AlertMessage} from "./components/common/AlertMessage";
import {closeSocketEventsConnection, connectToSocketServerDependingOnUser} from "./services/socketEventsService";
import {RestrictedRoute} from "./components/common/RestrictedRoute";
import {getSmartFarmAutomationServerTokenByUser} from "./api/apiAutomation";
import {HelpMain} from "./components/HelpMain";
import {SmartFarmDataProvider} from "./contexts/SmartFarmDataContext";
import {MaterialIcon} from "./components/common/icons/MaterialIcon";
import {deleteCookie, getCookie, setCookie} from "./services/CookiesService";

const queryClient = new QueryClient();

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#00cac0"
        },
        secondary: {
            main: "#00cac0"
        }
    }
});

export const App = () => {
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [alert, setAlert] = useState();

    const isRegistrationPage = window.location.href.indexOf('confirm') > -1 || window.location.href.indexOf('register') > -1;
    const [isPageOut, setIsPageOut] = useState(window.location.pathname.startsWith('/builder'));

    const [showGotoOfflineMode, setShowGotoOfflineMode] = useState(false);
    const [showGotoOnlineMode, setShowGotoOnlineMode] = useState(false);

    let token = localStorage.getItem('token');

    const userInLocalStorage = getCurrentUser();

    useEffect(() => {
        // Get new user data and update local storage
        getUserData().then(userData => {
            // If user is logged in, connect to socket server
            if(userData !== null) {
                connectToSocketServerDependingOnUser(userData);
                getSmartFarmAutomationServerTokenByUser(userData);
            }
        });

        return () => {
            // Close socket server connection
            closeSocketEventsConnection();
        };
    }, []);

    /* If a user is logged in, update user data in local storage */
    const getUserData = () => {
        return new Promise( resolve => {
            const alertData = {message: 'An error occurred while refreshing user data', status: 'error', date: new Date()};
            let userData = null;
            if(userInLocalStorage !== undefined && userInLocalStorage !== null && getCurrentUser().id) {
                fetchData('users/' + getCurrentUser().id)
                    .then(response => response.json())
                    .catch(() => {
                        setAlert(alertData);
                        disconnectUser();
                    })
                    .then(data => {
                        if(data !== undefined && data !== null && data.id) {
                            setCurrentUserInLocalStorage(data);
                            userData = data;
                            setIsLoading(false);
                            resolve(userData);
                        } else {
                            disconnectUser();
                        }
                    })
                    .catch(() => {
                        setAlert(alertData);
                        disconnectUser();
                    });
            } else {
                setIsLoading(false);
                resolve(userData);
            }
        });
    }

    useEffect(() => {
        const hasOfflineCookie = getCookie('offline');

        if(process.env.REACT_APP_IS_OFFLINE_APP === 'true' && hasOfflineCookie !== 'true'){
            setShowGotoOfflineMode(true);
            setCookie('offline', true, 1000);
            setTimeout(() => setShowGotoOfflineMode(false), 20000);
        }
        else if(process.env.REACT_APP_IS_OFFLINE_APP === 'false' && hasOfflineCookie === 'true'){
            setShowGotoOnlineMode(true);
            deleteCookie('offline');
            setTimeout(() => setShowGotoOnlineMode(false), 20000);
        }
    }, []);

    useEffect(() => {
        if(process.env.REACT_APP_DETECT_OFFLINE_MODE !== 'true')
            return;

        const handleOnline = () => {
            console.log('Switching to online app')
            window.location.replace(process.env.REACT_APP_BASE_PROTOCOL + '://' + process.env.REACT_APP_BASE_URL);
        };
        const handleOffline = () => {
            console.log('Switching to offline app')
            window.location.replace('http://offline.handddle.com')
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    // Wait for user request to end before access the app
    if(isLoading) return <Loading/>;

    // Check if the token expiration date has passed then redirect to login page if true
    if(!isRegistrationPage && (!token || jwt_decode(token).exp * 1000 < new Date().getTime())) {
        return (
            <div id="main-grid" className="view-content-only">
                <div id="main-grid__item--main-content" className="main-grid__item">
                    <LoginComponent setUser={setUser}/>
                </div>
            </div>
        )
    }

    return (
        <ThemeProvider theme={theme}>
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}

        <Router>
            {isRegistrationPage ?
                <Switch>
                    <Route path="/confirm/:token">
                        <QueryClientProvider client={queryClient}>
                            <Confirm/>
                        </QueryClientProvider>
                    </Route>
                    {/*<Route path="/register/:token">*/}
                    <Route path="/register">
                        <QueryClientProvider client={queryClient}>
                            <Register/>
                        </QueryClientProvider>
                    </Route>
                </Switch>
            : isPageOut ?
                <div className="main-page__pic">
                    <Route path="/builder">
                        <PicBuilderMain/>
                    </Route>
                </div>
            :
                <SmartFarmDataProvider>
                    <div id="main-grid">
                        {
                            process.env.REACT_APP_IS_OFFLINE_APP === 'true' &&
                                <div id="offline-mode-block">
                                    <MaterialIcon label="cloud_off"/>
                                    <p>You are working in offline mode.</p>
                                    <p>Last synchronization with cloud server: TODO</p>
                                </div>
                        }
                        {
                            showGotoOfflineMode &&
                                <div id="goto-offline-screen">
                                    <div className="block">
                                        <MaterialIcon label="cloud_off"/>
                                        <p>It seems you lost your internet connection.</p>
                                        <p>Switching to offline mode. Please wait...</p>
                                        <Loading/>
                                    </div>
                                </div>
                        }
                        {
                            showGotoOnlineMode &&
                                <div id="goto-offline-screen">
                                    <div className="block">
                                        <MaterialIcon label="cloud_done"/>
                                        <p>You have just reconnected to the internet.</p>
                                        <p>Switching to online mode. Please wait...</p>
                                        <Loading/>
                                    </div>
                                </div>
                        }
                        <div id="main-grid__item--main-menu" className="main-grid__item">
                            <div id="main-menu__menu-item--logo" className="main-menu__menu-item"/>
                            <MainMenu setIsPageOut={setIsPageOut}/>
                        </div>
                        <QueryClientProvider client={queryClient}>
                            <HeaderContent/>
                        </QueryClientProvider>
                        <Switch>
                            <Route exact path="/">
                                <FarmMain/>
                            </Route>
                            <RestrictedRoute path="/dashboard">
                                <DashboardMain/>
                            </RestrictedRoute>
                            <RestrictedRoute path="/gantt/schedule/:picId">
                                <GanttMain />
                            </RestrictedRoute>
                            <RestrictedRoute path="/gantt">
                                <GanttMain />
                            </RestrictedRoute>
                            <RestrictedRoute path="/manager">
                                <PicManagerMain/>
                            </RestrictedRoute>
                            <RestrictedRoute path="/history">
                                <HistoryMain/>
                            </RestrictedRoute>
                            <Route path="/printers">
                                <PrintersMain/>
                            </Route>
                            <Route path="/resources/:batchNumber">
                                <ResourcesMain/>
                            </Route>
                            <Route path="/resources">
                                <ResourcesMain/>
                            </Route>
                            <Route path="/monitoring/:farmCellId">
                                <FarmMain/>
                            </Route>
                            <Route path="/monitoring">
                                <FarmMain/>
                            </Route>
                            <RestrictedRoute path="/library/:pieceId">
                                <LibraryMain/>
                            </RestrictedRoute>
                            <RestrictedRoute path="/library">
                                <LibraryMain/>
                            </RestrictedRoute>
                            <RestrictedRoute path="/projects/:id">
                                <ProjectMain/>
                            </RestrictedRoute>
                            <RestrictedRoute path="/projects">
                                <ProjectsMain/>
                            </RestrictedRoute>
                            <Route path="/profile">
                                <ProfileMain/>
                            </Route>
                            <Route path="/organization">
                                <OrganizationMain/>
                            </Route>
                            <Route path="/members">
                                <MembersMain/>
                            </Route>
                            <Route path="/connectivity">
                                <ConnectivityMain/>
                            </Route>
                            <Route path="/help">
                                <HelpMain/>
                            </Route>
                            <Route path="/backoffice">
                                <BackofficeMain/>
                            </Route>
                        </Switch>
                    </div>
                </SmartFarmDataProvider>
            }
        </Router>
        </ThemeProvider>

    )
}
