import {Dialog, DialogContent, makeStyles, Drawer} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useParams, useHistory} from "react-router-dom";
import {useGetData} from "../../api/useFetch";
import "../../css/fullcalendar.css";
import {getCurrentDateAtMidnight} from "../../utils/date";
import {AlertMessage} from "../common/AlertMessage";
import {Error} from "../common/Error";
import {Loading} from "../common/Loading";
import {getCurrentTimestamp, getCurrentUser, getSelectedOrganizationId} from "../CommonFunctions";
import {Calendar} from "./calendar/Calendar";
import {PicSchedule} from "./PicSchedule";
import { Workload } from "./Workload";
import {IncomingTasksTable} from "./IncomingTasksTable";
import {getAllExternalTasks, patchDissociateTaskFromExternalPrint} from "../../api/apiGantt";
import {
    EXTERNAL_PRINT_TASK_REQUEST_INTERVAL,
    getPrinterInPrintersList,
    getTasksByPrinterId
} from "../../services/gantt/GanttService";
import {getTasksIncoming, smartScheduleTasks} from "../../services/gantt/SmartSchedulingService";
import { TaskDetailsMain } from "./right-panel/TaskDetailsMain";
import {ResourceChoice} from "./resource-choice/ResourceChoice";
import {FastPrint} from "./FastPrint";

const useStyles = makeStyles({
    paper: {
        left: "60%",
        background: "#f5f5f5"
    },
    dialog_paper: {
        maxWidth: "none",
        padding: "0 15px"
    },
    workload_paper:{
        maxWidth: '1050px',
        height: '800px'
    }
});

export const Gantt = () => {
    let {picId} = useParams();
    let history = useHistory();
    const [isMounted, setIsMounted] = useState(true);
    const [alert, setAlert] = useState();
    const [rightPartDetailsIsOpen, setRightPartDetailsIsOpen] = useState(false);
    const [newPageIndex, setNewPageIndex] = useState(false);
    const [isTaskUpdated, setIsTaskUpdated] = useState(false);
    const [taskInWaiting, setTaskInWaiting] = useState(null);
    const [taskId, setTaskId] = useState();
    const [picScheduleSelectedDate, setPicScheduleSelectedDate] = useState(getCurrentDateAtMidnight());
    const [selectedPrinterSchedule, setSelectedPrinterSchedule] = useState('');
    const [isPicScheduleDialogOpen, setIsPicScheduleDialogOpen] = useState(!!picId);
    const [isWorkloadDialogOpen, setIsWorkloadDialogOpen] = useState(false);
    const [isDissociateModalOpen, setIsDissociateModalOpen] = useState(false);
    const [printerTaskWorkload, setPrinterTaskWorkload] = useState([]);
    const classes = useStyles();
    const currentUser = getCurrentUser();
    const [tasks, setTasks] = useState([]);
    // Tasks detected by printer status that have not been manually added in gantt
    const [externalPrintTasks, setExternalPrintTasks] = useState([]);
    const [externalPrintTasksInterval, setExternalPrintTasksInterval] = useState(null);

    /* Fast print data */
    const [isFastPrintDialogOpen, setIsFastPrintDialogOpen] = useState(false);


    const [isLoading, setIsLoading] = useState(true);

    const printers = useGetData("printers", "printers/gantt", {
        organization: getSelectedOrganizationId()
    });

    const ressourcesResult = useGetData("resourcesData", "resource_items/essentials", {
        organization_group: currentUser.organization.organization_group.id,
        archived: false
    });

    const OrganizationResult = useGetData(`organizations`, `organizations`, {
        id: currentUser.organization.id
    });

    useEffect(() => {
        getExternalPrintTasks();
        setExternalPrintTasksInterval(setInterval(() => {
            getExternalPrintTasks();
        }, EXTERNAL_PRINT_TASK_REQUEST_INTERVAL * 1000));
        return () => {
            if(externalPrintTasksInterval !== null) {
                clearInterval(externalPrintTasksInterval);
            }
            setIsMounted(false);
        };
    }, []);

    useEffect(() => {
        if(printers.data !== undefined && isMounted) {
            handlePrintersDataChange();
            if(isLoading) {
                setIsLoading(false);
            }
        }
    }, [printers.data]);

    const handlePrintersDataChange = () => {
        //Fetch all task by printers
        const tasksList = printers.data.map((printer) => {
            const printerTasks = printer.tasks;
            if (printerTasks) {
                printerTasks.forEach((task) => {
                    task.printer = printer;
                });
            }
            return printerTasks;
        }).flat();
        setTasks(tasksList);
    }

    /* Get external tasks detected by printer status */
    const getExternalPrintTasks = () => {
        return getAllExternalTasks()
            .then(tasks => {
                setExternalPrintTasks(tasks);
                return tasks;
            })
            .catch(() => {
                setExternalPrintTasks([]);
                return [];
            });
    }

    const refetchPrinters = () => {
        return printers.refetch();
    };

    const refetchAllTasksAfterDelete = () => {
        printers.refetch();
        getExternalPrintTasks();
    };

    const dissociateTask = (task, isScheduleNeeded) => {
        patchDissociateTaskFromExternalPrint(task.id)
            .then(() => {
                setAlert({message: 'Task: ' + task.name + ' dissociated', status: 'success', date: new Date()});
                printers.refetch()
                    .then(printersData => {
                        clearInterval(externalPrintTasksInterval);
                        getExternalPrintTasks()
                            .then(externalTasks => {
                                if(isScheduleNeeded) {
                                    if(task.printer && task.printer.id) {
                                        schedulePrinterTasks(task.printer.id, externalTasks, printersData.data, task.id);
                                        setIsDissociateModalOpen(false);
                                    } else {
                                        setAlert({message: 'Task: ' + task.name + ' has been dissociated but an error occurred on smart schedule', status: 'warning', date: new Date()});
                                    }
                                } else {
                                    setIsDissociateModalOpen(false);
                                }
                            });
                        setExternalPrintTasksInterval(setInterval(() => {
                            getExternalPrintTasks();
                        }, EXTERNAL_PRINT_TASK_REQUEST_INTERVAL * 1000));
                    });
            })
            .catch(() => setAlert({message: 'Task: ' + task.name + ' could not be dissociated', status: 'error', date: new Date()}));
    }

    const schedulePrinterTasks = (printerId, externalTasks, printersData, taskId) => {
        if(printersData) {
            const printer = getPrinterInPrintersList(printersData, printerId);
            if(printer !== null) {
                let ganttTasks = printer.tasks ? printer.tasks : [];
                // Move dissociated task to 5 minutes in the future to allow auto schedule on it
                ganttTasks = moveDissociatedTaskBeforeSchedule(ganttTasks, taskId);
                if(ganttTasks.length > 0) {
                    const externalPrinterTasks = getTasksByPrinterId(externalTasks, printerId);
                    const tasksIncoming = getTasksIncoming(ganttTasks, externalPrinterTasks,
                        printer.printer_maintenances ? printer.printer_maintenances : []);
                    if(tasksIncoming.length > 0) {
                        smartScheduleTasks(tasksIncoming)
                            .then(tasksUpdated => {
                                if(tasksUpdated && tasksUpdated.length > 0) {
                                    refetchPrinters();
                                } else {
                                    setAlert({message: "No upcoming tasks updated", status: "warning", date: new Date()});
                                }
                            })
                            .catch((e) => setAlert({message: e, status: "error", date: new Date()}));
                    } else {
                        setAlert({message: "No upcoming tasks are planned", status: "warning", date: new Date()});
                    }
                } else {
                    setAlert({message: "No upcoming tasks are planned", status: "warning", date: new Date()});
                }
            } else {
                setAlert({message: "Unable to schedule tasks", status: "error", date: new Date()});
            }
        } else {
            setAlert({message: "Unable to schedule tasks", status: "error", date: new Date()});
        }
    }

    /* Move task corresponding to taskId date to 5 minutes in the future */
    const moveDissociatedTaskBeforeSchedule = (tasks, taskId) => {
        if(tasks) {
            for(let task of tasks) {
                if(task.id === taskId) {
                    task.date = getCurrentTimestamp() + 300;
                    break;
                }
            }
        }
        return tasks;
    }

    if (OrganizationResult.isLoading) {
        return <Loading />;
    }

    if (printers.isLoading || isLoading) {
        return <Loading />;
    }

    if (printers.isError) {
        return <Error errorMessage={printers.error.message} />;
    }
    const organization = OrganizationResult.data[0];
    const resources = ressourcesResult.data;

    const handleClickOpenDialog = () => {
        setIsPicScheduleDialogOpen(true);
    };

    const schedulePic = (date) => {
        setPicScheduleSelectedDate(date);
        setIsPicScheduleDialogOpen(true);
    };

    const handleOpenFastPrintDialog = () => {
        setIsFastPrintDialogOpen(true);
    };


    return (
        <div id="main__gantt">
            <Dialog
                classes={{paper: classes.dialog_paper}}
                open={isPicScheduleDialogOpen}
                onClose={() => {setIsPicScheduleDialogOpen(false); setTaskInWaiting(null); history.push('/gantt');}}
            >
                <DialogContent>
                    <PicSchedule
                        picId={picId}
                        callback={() => {
                            refetchPrinters();
                            setIsPicScheduleDialogOpen(false);
                        }}
                        setAlert={setAlert}
                        taskInWaiting={taskInWaiting}
                        setTaskInWaiting={setTaskInWaiting}
                        picScheduleSelectedDate={picScheduleSelectedDate}
                        setPicScheduleSelectedDate={setPicScheduleSelectedDate}
                        selectedPrinterSchedule={selectedPrinterSchedule}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                classes={{paper: classes.dialog_paper}}
                open={isFastPrintDialogOpen}
                onClose={() => setIsFastPrintDialogOpen(false)}
            >
                <DialogContent>
                    <FastPrint
                        callback={() => {
                            refetchPrinters();
                            setIsFastPrintDialogOpen(false);
                        }}
                        setAlert={setAlert}
                        printers={printers.data ? printers.data : []}
                    />
                </DialogContent>
            </Dialog>
            
            <Dialog
                classes={{paper: classes.workload_paper}}
                open={isWorkloadDialogOpen}
                onClose={() => setIsWorkloadDialogOpen(false)}
            >
                <DialogContent>
                    <Workload
                        data={printerTaskWorkload}
                        setAlert={setAlert}
                        label="Workload"
                        color="#00CAC0"
                        ymin={0}
                        ymax={100}
                        resources={resources}
                        
                    />
                </DialogContent> 
            </Dialog>

            <Calendar
                printers={printers.data ? printers.data : []}
                schedulePic={schedulePic}
                setAlert={setAlert}
                setRightPartDetailsIsOpen={setRightPartDetailsIsOpen}
                setTaskId={setTaskId}
                setIsWorkloadDialogOpen={setIsWorkloadDialogOpen}
                setPrinterTaskWorkload={setPrinterTaskWorkload}
                setSelectedPrinterSchedule={setSelectedPrinterSchedule}
                taskResult={tasks}
                externalPrintTasks={externalPrintTasks}
                organization={organization}
                dissociateTask={dissociateTask}
                isDissociateModalOpen={isDissociateModalOpen}
                setIsDissociateModalOpen={setIsDissociateModalOpen}
                reloadCalendar={refetchPrinters}
                handleOpenFastPrintDialog={handleOpenFastPrintDialog}
            />
            {alert && <AlertMessage key={alert.date} message={alert.message} status={alert.status} />}
            <IncomingTasksTable
                tasks={tasks} handleClickOpenDialog={handleClickOpenDialog} setTaskInWaiting={setTaskInWaiting}
                setAlert={setAlert} reloadCalendar={refetchPrinters}
            />

            <Drawer anchor="right" open={rightPartDetailsIsOpen}
                    onClose={() => {
                        setRightPartDetailsIsOpen(!rightPartDetailsIsOpen)
                        setNewPageIndex(newPageIndex + 1)
                        if(isTaskUpdated){
                            setIsTaskUpdated(false);
                            refetchPrinters();
                        }
                    }}
                    classes={{paper: classes.paper}}>
                <TaskDetailsMain
                    taskId={taskId}
                    setRightPartDetailsIsOpen={setRightPartDetailsIsOpen}
                    setIsTaskUpdated={setIsTaskUpdated}
                    reloadPageData={refetchAllTasksAfterDelete}
                />
            </Drawer>

            {/* Resource choice */}
            <ResourceChoice setAlert={setAlert}/>
        </div>
    );
};
