import React, {useMemo} from "react";
import {areAllSoundModulesInactive, getFarmElementClassesWithMargins} from "../../../services/FarmService";
import {MaterialIcon} from "../../common/icons/MaterialIcon";
import {useSmartFarmData} from "../../../contexts/SmartFarmDataContext";

export const FarmControlPanel = (props) => {

    const {isPanelSideLeft, openRightStructurePanel} = props;

    const {smartFarmData} = useSmartFarmData();

    const isSoundModuleActive = useMemo(() => {
        return !areAllSoundModulesInactive(smartFarmData);
    }, [smartFarmData]);

    const getControlPanelLink = () => {
        return <div className="control-panel-link"></div>;
    }

    return(
        <div className={getFarmElementClassesWithMargins('control-panel', isPanelSideLeft, !isPanelSideLeft)}>
            <div className="control-panel-block">
                {!isPanelSideLeft && getControlPanelLink()}
                <div className="control-panel-screen-block" onClick={() => openRightStructurePanel()}>
                    <div className="control-panel-screen">
                        <div className="control-panel-content-block">
                            <div className="control-panel-text">Control panel</div>
                            <div><MaterialIcon classes="control-panel-icon" label={isSoundModuleActive ? 'volume_up' : 'volume_off'}/></div>
                        </div>
                    </div>
                    {/* Buzzer */}
                    <div className="stop-buzzer-block">
                        <div className="stop-buzzer-button">
                            <div className="stop-buzzer-button-content-icon">
                                <div className="stop-buzzer-icon"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {isPanelSideLeft && getControlPanelLink()}
            </div>
        </div>
    );
}
