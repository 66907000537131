import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import {getCurrentUser, getSelectedOrganizationId} from "../CommonFunctions";
import {useAPIRequest} from "../../api/useFetch";
import {Loading} from "../common/Loading";
import {Error} from "../common/Error";
import {Gantt} from "../gantt/Gantt";
import {Toggle} from "rsuite";

export const Dashboard = () => {
    const currentUser = getCurrentUser();

    const [visibleOrganizationId, setVisibleOrganizationId] = useState(null);

    const organizationGroupDataUrl = process.env.REACT_APP_API_ADDRESS + 'organization_groups/' +
        currentUser.organization.organization_group.id + '?groups[]=read:OrganizationGroup:Printers';
    const {data: organizationGroupData, isLoading: organizationGroupDataIsLoading, isError: organizationGroupDataIsError} = useAPIRequest(organizationGroupDataUrl);

    const organizationDataUrl = process.env.REACT_APP_API_ADDRESS + 'organizations/' +
      getSelectedOrganizationId() + '?groups[]=read:OrganizationGroup:Printers';
    const {data: organizationData, isLoading: organizationDataIsLoading, isError: organizationDataIsError} = useAPIRequest(organizationDataUrl);

    // Printers data
    let printers = [];

    if(organizationGroupData !== undefined && organizationData !== undefined && organizationGroupData !== null && organizationData !== null){
        if(visibleOrganizationId !== null)
            printers = organizationData.printers;

        else
            printers = organizationGroupData.organizations.flatMap(organization => organization.printers)
    };

    let totalPrintersUsageHours = Math.round(printers.reduce((partialSum, printer) => partialSum + printer.total_used_duration, 0) / 3600);
    let totalPrintersCapacity = printers.length * 24 * 7;

    // Most used stuff data
    let mostUsedPrintersData, mostUsedResourcesData;

    if(organizationGroupData !== undefined && organizationData !== undefined && organizationGroupData !== null && organizationData !== null){
        let printersNames = [];
        let printersDurations = [];
        let referencesNames = [];
        let referencesQuantities = [];

        // Specific organization
        const data = visibleOrganizationId !== null ? organizationData : organizationGroupData;

        for(const [printerName, duration] of Object.entries(data.most_used_printers)){
            printersNames.push(printerName);
            printersDurations.push(duration);
        }

        for(const [referenceName, quantity] of Object.entries(data.most_used_resources)){
            referencesNames.push(referenceName);
            referencesQuantities.push(quantity);
        }

        mostUsedPrintersData = {
            options: {
                labels: printersNames,
                title: {
                    text: 'Most used printers (hours)'
                }
            },
            series: printersDurations
        };

        mostUsedResourcesData = {
            options: {
                labels: referencesNames,
                title: {
                    text: 'Most used materials (grams)'
                }
            },
            series: referencesQuantities
        };

    }

    const isToggleVisibilityEnabled = visibleOrganizationId == null;

    const handleVisibilityModeChange = (isEnabled) => {
        if(isEnabled)
            setVisibleOrganizationId(null);
        else
            setVisibleOrganizationId(getSelectedOrganizationId());
    };

    if(organizationDataIsLoading || organizationGroupDataIsLoading) return <Loading/>
    if(organizationDataIsError || organizationGroupDataIsError) return <Error errorMessage="Something went wrong."/>

    return (
        <div id="main__dashboard">

            <div id="dashboard__header">
                <h1>Dashboard</h1>
                <div className="toggle_zone">
                    <span>Current organization</span>
                    <Toggle className="toggle" checked={isToggleVisibilityEnabled} onChange={isEnabled => handleVisibilityModeChange(isEnabled)}/>
                    <span>All organizations</span>
                </div>
            </div>

            <div id="dashboard__grid">
                <div className="dashboard-block">
                    <div className="figures">
                        <span>{printers.length}</span> printers available
                    </div>
                    <div className="figures">
                        <span>{totalPrintersUsageHours}h</span> total printers usage
                    </div>
                    <div className="figures">
                        <span>{totalPrintersCapacity}h</span> total production capacity (hours/week)
                    </div>
                </div>

                <div className="dashboard-block">
                    <div className="refill-success">
                        <i className="fa fa-check" aria-hidden="true"/>
                        Sufficient raw materials to cover production schedules
                    </div>
                </div>

                <div className="dashboard-block-flex-zone">
                    <div className="dashboard-block">
                        <div className="dashboard-chart">
                            <Chart
                              options={mostUsedPrintersData.options}
                              series={mostUsedPrintersData.series}
                              type="donut"
                              height="300px"
                            />
                        </div>
                    </div>
                    <div className="dashboard-block">
                        <div className="dashboard-chart">
                            <Chart
                              options={mostUsedResourcesData.options}
                              series={mostUsedResourcesData.series}
                              type="donut"
                              height="300px"
                            />
                        </div>
                    </div>
                </div>

                <div className="dashboard-block">
                    <Gantt/>
                </div>
            </div>
        </div>
    );
}
