import {Dialog, DialogContent, makeStyles} from "@material-ui/core";
import React, {useState} from "react";
import {FarmProfileForm} from "./FarmProfileForm";
import {AlertMessage} from "../../common/AlertMessage";

const useStyles = makeStyles({
    dialog_paper: {
        maxWidth: "none",
        padding: "0 15px"
    }
});

export const FarmProfileModal = (props) => {

    const {isOpen, closeModal, systemType, farmProfiles, getFarmProfiles} = props;

    const classes = useStyles();

    const getNewProfile = () => {
        return {id: -1, name: '', temperature: 20, humidity: 20, system_type: systemType,
            printer_brand: {id: - 1}, printer_model: {id: - 1}, material: {id: -1}, tolerance_percentage: 5};
    }

    const [alert, setAlert] = useState();
    const [selectedProfile, setSelectedProfile] = useState(() => getNewProfile());

    const onSaveSuccessCallback = (profile) => {
        getFarmProfiles()
            .then(() => setSelectedProfile(profile))
            .catch(() => setSelectedProfile(getNewProfile()));
        setAlert({date: new Date(), message: 'Profile saved', status: 'success'});
    }

    const onSaveErrorCallback = () => {
        setAlert({date: new Date(), message: 'An error occurred while saving profile', status: 'error'});
    }

    const onDeleteSuccessCallback = () => {
        setSelectedProfile(getNewProfile());
        getFarmProfiles();
        setAlert({date: new Date(), message: 'Profile deleted', status: 'success'});
    }

    const onDeleteErrorCallback = () => {
        setAlert({date: new Date(), message: 'An error occurred while deleting profile', status: 'error'});
    }

    const handleSelectedProfileChange = (id) => {
        if(parseInt(id) === -1) {
            setSelectedProfile(getNewProfile());
        } else {
            setSelectedProfile(farmProfiles ? farmProfiles.filter(profile => profile.id === parseInt(id))[0] : getNewProfile());
        }
    }

    const handleCloseModal = () => {
        setSelectedProfile(getNewProfile());
        closeModal();
    }

    return(
        <Dialog open={isOpen} onClose={() => handleCloseModal()} classes={{paper: classes.dialog_paper}}>
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}
            <DialogContent>
                <div className="farm-profile-modal">
                    <h2 className="profile-modal-title">Manage profiles</h2>

                    <div className="profile-selection">
                        {/* select profile to edit */}
                        <div>
                            <label htmlFor="select-profile" className="farm-profile-label">Select profile to edit</label>
                            <select
                                id="select-profile"
                                value={(selectedProfile && selectedProfile.id) ? selectedProfile.id : -1}
                                onChange={(e) => handleSelectedProfileChange(e.target.value)}
                            >
                                <option value={-1} key="new-profile">-- New Profile --</option>
                                {farmProfiles && farmProfiles.map(profile =>
                                    <option value={profile.id} key={profile.id}>{profile.name}</option>
                                )}
                            </select>
                        </div>
                        {/* new profile */}
                        <button type="button" onClick={() => setSelectedProfile(getNewProfile())} className="simple-profile-button simple-main-button">
                            <i className="fa fa-plus"/>
                            <span>Create new profile</span>
                        </button>
                    </div>
                    {/* profile form */}
                    {isOpen &&
                        <FarmProfileForm
                            selectedProfile={selectedProfile}
                            saveSuccessCallback={onSaveSuccessCallback} saveErrorCallback={onSaveErrorCallback}
                            deleteSuccessCallback={onDeleteSuccessCallback} deleteErrorCallback={onDeleteErrorCallback}
                            systemType={systemType} handleCloseModal={handleCloseModal}
                        />
                    }
                </div>
            </DialogContent>
        </Dialog>
    );
}
