import {OutlinedCard} from "../../common/OutlinedCard";
import React, {useEffect, useState} from "react";
import {Toggle} from "rsuite";
import {sendFarmCommandEvent} from "../../../../services/socketEventsService";
import {CirclePicker} from "react-color";
import {StopCurrentModal} from "../modals/StopCurrentModal";
import {DigitCode} from "../../../common/DigitCode";
import {
    CELL_TYPES, DOOR_STATES,
    FARM_EVENT_TYPES,
    getLedColorsColorsWithoutOff,
    getLedColorsIndexesWithoutOff,
    LED_COLORS, ON_OFF_STATES, SMART_FARM_COMMANDS, SMART_FARM_DATA_FIELDS
} from "../../../../services/FarmService";
import {MaterialIcon} from "../../../common/icons/MaterialIcon";
import {EnvironmentProfilesBlock} from "./EnvironmentProfilesBlock";
import {useSmartFarmData} from "../../../../contexts/SmartFarmDataContext";

export const EnvironmentManagerBlock = (props) => {

    const {farmCell, saveFarmEvent, isPrinterPrinting, saveOpenDoor} = props;

    // Profiles props
    const {farmProfiles, systemType, setAlert, getFarmProfiles} = props;

    const {smartFarmData} = useSmartFarmData();
    const smartFarmCellData = smartFarmData?.[farmCell?.system_code];

    // On/Off
    const isOn = () => parseInt(smartFarmCellData?.[SMART_FARM_DATA_FIELDS.ON_OFF]) === ON_OFF_STATES.ON;

    // Stop printer
    const [showStopCurrentModal, setShowStopCurrentModal] = useState(false);
    const cancelModal = () => setShowStopCurrentModal(false);

    const getLedColor = () => {
        if(smartFarmCellData && smartFarmCellData[SMART_FARM_DATA_FIELDS.LED_COLOR])
            return parseInt(smartFarmCellData[SMART_FARM_DATA_FIELDS.LED_COLOR]);
        return LED_COLORS.OFF.index;
    }

    const getIsLighting = () => {
        return getLedColor() !== LED_COLORS.OFF.index;
    }

    // Lighting and colors
    const [isLighting, setIsLighting] = useState(getIsLighting());
    const [selectedColorIndex, setSelectedColorIndex] = useState(getLedColor());
    const COLORS_VALUES = getLedColorsColorsWithoutOff();
    const COLORS_INDEXES = getLedColorsIndexesWithoutOff();

    const getDoorStatus = () => {
        if(!smartFarmCellData || smartFarmCellData.door_state === undefined || smartFarmCellData.door_state === null) {
            return null;
        }
        return smartFarmCellData.door_state === DOOR_STATES.CLOSED;
    }

    // Door
    const [isDoorClosed, setIsDoorClosed] = useState(getDoorStatus());
    const [isOpenDoorDigitCodeVisible, setIsOpenDoorDigitCodeVisible] = useState(false);

    useEffect(() => {
        // Update isDoorClosed value with influxdb data if value has changed
        if(smartFarmCellData && smartFarmCellData.door_state !== undefined && smartFarmCellData.door_state !== null && ((smartFarmCellData.door_state === DOOR_STATES.CLOSED) !== isDoorClosed)) {
            setIsDoorClosed(smartFarmCellData.door_state === DOOR_STATES.CLOSED);
        }
        // Update led color
        setIsLighting(getIsLighting());
        setSelectedColorIndex(getLedColor());
    }, [smartFarmCellData]);

    const handleOnOff = () => {
        const newState = isOn() ? ON_OFF_STATES.OFF : ON_OFF_STATES.ON;
        sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.ON_OFF, newState);
        saveFarmEvent(FARM_EVENT_TYPES.ON_OFF, newState);
    };

    const handleLighting = (is_lighting) => {
        const colorIndex = is_lighting ? LED_COLORS.WHITE.index : LED_COLORS.OFF.index;
        setIsLighting(is_lighting);
        setSelectedColorIndex(colorIndex);
        sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.LED_COLOR, colorIndex);
    }

    const handleColorChange = (newColor) => {
        const colorIndex = COLORS_INDEXES[COLORS_VALUES.indexOf(newColor.hex)];
        setSelectedColorIndex(colorIndex);
        setIsLighting(true);
        sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.LED_COLOR, colorIndex);
    };

    const handleOpenDoor = (user) => {
        const userId = user.id ? user.id : null;
        const topSystemCode = farmCell.system_code.replace(/[RSB]/g, 'T');

        sendFarmCommandEvent(topSystemCode, SMART_FARM_COMMANDS.OPEN_DOOR, 0);

        saveOpenDoor(topSystemCode, userId);
    };

    return(
        <OutlinedCard>
            <DigitCode
                visible={isOpenDoorDigitCodeVisible} setVisible={setIsOpenDoorDigitCodeVisible}
                callback={handleOpenDoor} errorCallback={() => null}
            />

            <div className="farm__dialog_environment_manager farm__dialog_common">
                {/* Header */}
                <div className="header-block">
                    <h2>Environment Manager</h2>
                </div>

                <div className="grid-zone">
                    <div>
                        {/* On / off */}
                        <div className="toggle-label-right farm-command-line">
                            <Toggle checked={isOn()} onChange={handleOnOff}/>
                            <span className="toggle-label">Off/On</span>
                        </div>
                        {/* Module lighting */}
                        <div className="toggle-label-right">
                            <Toggle checked={isLighting} onChange={is_lighting => handleLighting(is_lighting)}/>
                            <span className="toggle-label">Module lighting</span>
                        </div>
                    </div>
                    <div className="right">
                        {farmCell.system_type === CELL_TYPES.PRINTER &&
                          <button
                            className="button-danger"
                            type="button"
                            onClick={() => setShowStopCurrentModal(true)}>
                              <MaterialIcon label="warning" classes="button-icon-left"/>
                              <span>Stop printer</span>
                          </button>
                        }
                        {/* Lighting */}
                        <div className="light-color-zone">
                            <div>
                                <CirclePicker
                                  colors={COLORS_VALUES}
                                  color={COLORS_VALUES[COLORS_INDEXES.indexOf(selectedColorIndex)]}
                                  circleSize={20}
                                  onChange={(color) => handleColorChange(color)}
                                  width="auto"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Door */}
                <div className="door-block">
                    <button className=""
                        type="button" onClick={() => setIsOpenDoorDigitCodeVisible(true)}>
                        Open door
                    </button>
                    <div className="door-status">
                        <span className="door-status-label">Door status:</span>
                        {isDoorClosed === null ?
                            <span className="font-bold">--</span> :
                            <span className="font-bold">{isDoorClosed ? 'Closed' : 'Opened'}</span>
                        }
                    </div>
                </div>
            </div>

            {/* Modal */}
            {showStopCurrentModal &&
                <StopCurrentModal farmCell={farmCell} cancelModal={cancelModal} saveFarmEvent={saveFarmEvent}/>
            }

            <EnvironmentProfilesBlock
                farmProfiles={farmProfiles} systemType={systemType} setAlert={setAlert} getFarmProfiles={getFarmProfiles}
                farmCell={farmCell} saveFarmEvent={saveFarmEvent}/>
        </OutlinedCard>
    );
}
