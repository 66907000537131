import {getCurrentUser, getSelectedOrganizationId} from "../components/CommonFunctions";

const token = localStorage.getItem('token');

/*POST function to add new resource*/
export const postResource = async (dataResource) => {

    // Brand
    let brand = JSON.parse(dataResource.brand);

    //Technology
    let technology = JSON.parse(dataResource.technology);

    //Color
    let color = technology.has_color ? process.env.REACT_APP_API_URI + `resource_colors/${dataResource.color}` : null;

    //Diameter
    let diameter = technology.has_diameter ? parseInt(dataResource.diameter) : 0;

    if(dataResource.copies_number === null || dataResource.copies_number === undefined)
        dataResource.copies_number = 1;

    for(let i=1; i <= dataResource.copies_number; i++) {
        let serial_number_ending = '-' + i;

        if(dataResource.copies_number === 1)
            serial_number_ending = null;

        const currentUser = getCurrentUser();

        await fetch(process.env.REACT_APP_API_ADDRESS + 'resource_items', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                organization: process.env.REACT_APP_API_URI + `organizations/${getSelectedOrganizationId()}`,
                code: '-',
                brand: process.env.REACT_APP_API_URI + `resource_brands/${brand.id}`,
                color: color,
                material: process.env.REACT_APP_API_URI + `materials/${dataResource.material}`,
                serial_number: dataResource.serial_number + (serial_number_ending ? serial_number_ending : ''),
                diameter: diameter,
                remaining_quantity: dataResource.remaining_quantity
            })
        }).then((response) => {
            if(!response.ok)
                throw Error;
        })
    }
}

/*PUT function to edit a resource*/
export const patchResource = async (dataResource) => {

    // Brand
    let brand = JSON.parse(dataResource.brand);

    //Technology
    let technology = JSON.parse(dataResource.technology);

    //Color
    let color = technology.has_color ? process.env.REACT_APP_API_URI + `resource_colors/${dataResource.color}` : null;

    //Diameter
    let diameter = technology.has_diameter ? parseInt(dataResource.diameter) : 0;

    // Edits
    dataResource.resource_edits.map(edit => {
        edit.user = edit.user.id ? process.env.REACT_APP_API_URI + 'users/' + edit.user.id : process.env.REACT_APP_API_URI + 'users/' + edit.user;
    });

    /* TODO: add user organization group/organization*/
    await fetch(process.env.REACT_APP_API_ADDRESS + `resource_items/${dataResource.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            organization_group: process.env.REACT_APP_API_URI + 'organization_groups/1',
            code: '-', /* @TODO Generate random code */
            brand: process.env.REACT_APP_API_URI + `resource_brands/${brand.id}`,
            color: color,
            material: process.env.REACT_APP_API_URI + `materials/${dataResource.material}`,
            serial_number: dataResource.serial_number,
            diameter: diameter,
            resource_edits: dataResource.resource_edits
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const patchResourceRemainingQuantity = async (resourceId, remainingQuantity) => {
    await fetch(process.env.REACT_APP_API_ADDRESS + `resource_items/${resourceId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            remaining_quantity: remainingQuantity,
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
};

export const deleteResource = async (resource) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `resource_items/${resource}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const archiveResource = async (dataResource) => {

    fetch(process.env.REACT_APP_API_ADDRESS + `resource_items/${dataResource.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            archived: dataResource.archived,
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

