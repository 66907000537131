import React, {useEffect, useState} from "react";
import {FarmProfileModal} from "../../farm-profiles/FarmProfileModal";
import {Tooltip} from "@material-ui/core";
import {sendFarmCommandEvent} from "../../../../services/socketEventsService";
import {useSmartFarmData} from "../../../../contexts/SmartFarmDataContext";
import {FARM_EVENT_TYPES} from "../../../../services/FarmService";

export const EnvironmentProfilesBlock = (props) => {

    const {farmProfiles, systemType, setAlert, getFarmProfiles, farmCell, saveFarmEvent} = props;

    const {runningProfileId, setRunningProfileId} = useSmartFarmData();

    const [isEnvironmentProfilesModalOpen, setIsEnvironmentProfilesModalOpen] = useState(false);
    const [filteredFarmProfiles, setFilteredFarmProfiles] = useState([]);

    useEffect(() => {
        if(farmProfiles) {
            setFilteredFarmProfiles(farmProfiles.filter(profile => profile.system_type === systemType));
        }
    }, [farmProfiles]);

    /* Run selected profile */
    const activateProfile = (profile) => {
        sendFarmCommandEvent(farmCell.system_code, 'temperature', profile.temperature);
        sendFarmCommandEvent(farmCell.system_code, 'profile_id', profile.id);
        setRunningProfileId(profile.id);
        saveFarmEvent(FARM_EVENT_TYPES.PROFILE, 1, null, profile.id);
    }

    /* Cancel running profile actions and deselect it */
    const cancelProfile = () => {
        sendFarmCommandEvent(farmCell.system_code, 'temperature', -1);
        sendFarmCommandEvent(farmCell.system_code, 'profile_id', null);
        setRunningProfileId(null);
        saveFarmEvent(FARM_EVENT_TYPES.PROFILE, 0, null, null);
    }

    const handleProfileClick = (profile) => {
        if(profile.id === runningProfileId) {
            cancelProfile();
        } else {
            activateProfile(profile);
        }
    }

    /* Get content of profile details in tooltip */
    const getProfileTooltipContent = (profile) => {
        return(
            <div className="farm-manager-profile-tooltip-text">
                {(profile.temperature !== undefined && profile.temperature !== null) &&
                    <div>{'Temperature: ' + profile.temperature + '°C'}</div>
                }
                {(profile.humidity !== undefined && profile.humidity !== null) &&
                    <div>{'Humidity: ' + profile.humidity + '%'}</div>
                }
            </div>
        );
    }

    return(
        <>
            {/* Profiles modal */}
            <FarmProfileModal
                isOpen={isEnvironmentProfilesModalOpen} closeModal={() => setIsEnvironmentProfilesModalOpen(false)}
                systemType={systemType} farmProfiles={filteredFarmProfiles} getFarmProfiles={getFarmProfiles}
            />

            <div className="farm__dialog_environment_profiles">
                {/* Header */}
                <div className="header-block">
                    <h2>Environment Profiles</h2>
                </div>
                <div>
                    <button className="button-outlined-main" type="button"
                            onClick={() => setIsEnvironmentProfilesModalOpen(true)}>
                        Manage profiles
                    </button>
                    {filteredFarmProfiles.map(profile =>
                        <Tooltip title={getProfileTooltipContent(profile)} arrow placement="top" key={profile.id}>
                            <button
                                className={profile.id === runningProfileId ? 'profile-button profile-button-running' : 'profile-button'}
                                type="button" onClick={() => handleProfileClick(profile)}
                            >
                                {`${profile.name} (${profile.temperature}°C)`}
                            </button>
                        </Tooltip>
                    )}
                    {(!filteredFarmProfiles || filteredFarmProfiles.length === 0) &&
                        <p>No profile</p>
                    }
                </div>
            </div>
        </>
    );
}
