import React, {useCallback, useState, useRef} from 'react'
import IconMoreDefault from "../../images/icons/pic/IconMoreDefault";
import {useDropzone} from 'react-dropzone';
import axios from "axios";
import {CircularProgress, LinearProgress} from "@material-ui/core";

let globalUploadProgress = {};
let globalUploadTotal = 0;

export const PlatformDropZone = (props) => {

    const {uploadEndpoint, createRequestBody, onFileUploaded, onFileNotUploaded, uploadedPieces} = props;

    const [selectedNumber, setSelectedNumber] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const hiddenFileInput = useRef(null);
    const [, refreshState] = useState();

    const uploadFile = (file, onUploadProgress) => {
        // Create request body
        let data = new FormData();

        data.append('file', file);

        const requestBody = createRequestBody(file);
        Object.entries(requestBody).forEach(([key, value]) => {
            data.append(key, value);
        });

        // Send the request
        axios.post(process.env.REACT_APP_API_ADDRESS + uploadEndpoint, data, {
            headers: {
                'Content-Type': "multipart/form-data",
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            onUploadProgress
        })
            .then(response => {
                setIsUploading(false);

                // When upload finished without error
                if(response.status === 201 && onFileUploaded !== undefined){
                    onFileUploaded(response);
                }

                // In case of error
                else if(onFileNotUploaded !== undefined){
                    onFileNotUploaded(response);
                }
            })
            .catch(error => {
                setIsUploading(false);

                if(onFileNotUploaded !== undefined){
                    onFileNotUploaded(error);
                }
            });
    };

    const uploadFiles = (files) => {
        for(const file of files){
            globalUploadTotal += file.size;

            uploadFile(file, (e) => {
                globalUploadProgress[file.name] = e.loaded; // Obliged to use a global variable because of concurrency
                refreshState({}); // Hack to re-render
            });
        }
    };

    const onDrop = useCallback(acceptedFiles => {
        globalUploadProgress = {};
        globalUploadTotal = 0;
        refreshState({}); // Hack to re-render

        setSelectedNumber(acceptedFiles.length);
        setIsUploading(true);

        uploadFiles(acceptedFiles);
    }, []);

    const handleAddAttachmentFile = (e) => {
        refreshState({});
        setIsUploading(true);
        uploadFiles(e.target.files);
    }

    const {getRootProps, getInputProps, open, isDragActive} = useDropzone({onDrop, noClick: true, noKeyboard: true});

    let progress = 0;
    if(globalUploadTotal > 0 && Object.keys(globalUploadProgress).length){
        let sumUploadProgress = 0;

        for(const p of Object.values(globalUploadProgress))
            sumUploadProgress += p;

        progress = Math.min(100, sumUploadProgress / globalUploadTotal * 100);
    }

    return (
        uploadEndpoint === 'pieces' ? (
            !isUploading ? (
                <div {...getRootProps()} onClick={open} className={'drag-drop-zone' + (uploadedPieces.length ? ' reduced' : '')}>
                    {!uploadedPieces.length ?
                        <div className="full-zone">
                            <input {...getInputProps({
                                accept: '.stl',
                            })} />
                            {
                                isDragActive ?
                                    <p>Release to send...</p> :
                                    <>
                                        <h2>Drag and drop your files here or </h2>
                                        <button type="button">Select your files</button>
                                        <p>Currently files supported: STL</p>
                                        <p>Privacy: all your files are secured with us, read our <a href="#" target="_blank">privacy policy</a></p>
                                    </>
                            }
    
                        </div>
                        :
                        <div {...getRootProps()}>
                            <input {...getInputProps({
                                accept: '.stl',
                            })} />
                            {
                                isDragActive ?
                                    <p>Release to send...</p> :
                                    <>
                                        <IconMoreDefault className="add-pieces-icone"/>
                                        <h2>Click here to upload additional parts to your platform</h2>
                                        <p>(or drag and drop your parts)</p>
                                    </>
                            }
                        </div>
                    }
                </div>
            ) : progress < 100 ? (
                <>
                    <div className="progresszone">
                        <p>
                            {selectedNumber} selected file(s)
                        </p>
    
                        <LinearProgress variant="determinate" value={progress} />
    
                    </div>
                </>
            ) : (
                <>
                    <div className="progresszone">
                        <CircularProgress />
                        <p>Uploading...</p>
                    </div>
                </>
            )
        ) : (
            !isUploading ? (
                <button onClick={() => {
                    hiddenFileInput.current.click();
                }}>
                    <h2>Add other files</h2>
                    <input type="file" ref={hiddenFileInput} hidden onChange={handleAddAttachmentFile}/>
                </button>
            ) : (
                <>
                    <div className="progresszone progressAttachment">
                        <CircularProgress />
                        <p>Uploading attachments...</p>
                    </div>
                </>
            )
        )
    )
}