import {
    CELL_TYPES, FARM_EVENT_TYPES, getCellsIdFromFarmStructure, getManualPrinterStatus,
    getMaterialCellStatus, getPrinterStatusByPrinterId, SMART_FARM_COMMANDS,
    SMART_FARM_DATA_FIELDS
} from "../../../services/FarmService";
import {MaterialStatusBlock} from "./blocks/MaterialStatusBlock";
import {HistoryEventsBlock} from "./blocks/HistoryEventsBlock";
import {useGetData} from "../../../api/useFetch";
import {Loading} from "../../common/Loading";
import {Error} from "../../common/Error";
import {isUserGranted} from "../../CommonFunctions";
import {EnvironmentManagerBlock} from "./blocks/EnvironmentManagerBlock";
import React, {useCallback, useEffect, useState} from "react";
import {postFarmEvent} from "../../../api/apiFarmCommand";
import {sendFarmCommandEvent} from "../../../services/socketEventsService";
import {DigitCode} from "../../common/DigitCode";
import {OutlinedCard} from "../common/OutlinedCard";
import {useSmartFarmData} from "../../../contexts/SmartFarmDataContext";

export const FarmDialogCell = (props) => {

    const {
        farmCell, farmVersion, farmProfiles, getFarmProfiles, setAlert,
        getChartsDataRangeBlock, getHumidityBlock, getTemperatureBlock, getPollutionBlock,
        getMaintenanceBlock, cellMaintenance, structureMaintenance, farmStructure} = props;

    const farmStructureId = farmStructure ? farmStructure.id : null;

    const {smartFarmData, smartPrintersStatusData, manualPrintersStatus, setRunningProfileId} = useSmartFarmData();
    const smartFarmCellData = smartFarmData?.[farmCell?.system_code];

    const isPrinterPrinting = () => {
        for(let printer of farmCell.printers){
            const printerStatus = getPrinterStatusByPrinterId(printer.id, manualPrintersStatus);
            const manualStatus = getManualPrinterStatus(printerStatus !== null ? printerStatus.status : null);
            const smartStatus = getPrinterStatusByPrinterId(printer.id, smartPrintersStatusData);

            if((manualStatus !== undefined && manualStatus !== null && manualStatus.value !== 'free') ||
                (smartStatus !== undefined && smartStatus !== null && smartStatus.status.value !== 'free'))
                return true;
        }
        return false;
    };

    const historyEvents = useGetData('farm_events', 'farm_events?farm_cell=' + farmCell.id);

    useEffect(() => {
        let profileId = smartFarmData?.[farmCell?.system_code]?.profile_id;
        if(profileId === undefined) profileId = null;
        setRunningProfileId(parseInt(profileId));
    }, [smartFarmData]);

    const saveFarmEvent = (type, value, userId = null, farmProfileId = null) => {
        postFarmEvent(farmCell.id, type, value, userId, false, farmProfileId)
            .then(() => historyEvents.refetch())
            .catch(() => null);
    }

    const saveOpenDoor = (originalSystemCode, userId) => {
        const cellsIds = getCellsIdFromFarmStructure(farmStructure);
        const moduleNumber = originalSystemCode.replace(/\D/g, '');
        for(const letter of ['T', 'R', 'S', 'B']){
            const systemCode = letter + '-' + moduleNumber;
            if(cellsIds[systemCode] !== undefined){
                postFarmEvent(cellsIds[systemCode], FARM_EVENT_TYPES.OPEN_DOOR, 0, userId)
                    .then(() => historyEvents.refetch())
                    .catch(() => null);
            }
        }
    }

    const [temperatureTmpValue, setTemperatureTmpValue] = useState(null);
    const [extractionTmpValue, setExtractionTmpValue] = useState(null);
    const [isTemperatureDigitCodeVisible, setIsTemperatureDigitCodeVisible] = useState(false);
    const [isExtractionDigitCodeVisible, setIsExtractionDigitCodeVisible] = useState(false);
    const [profileIdTmpValue, setProfileIdTmpValue] = useState(null);

    const handleTemperatureChange = (newTemperature, farmProfileId=null) => {
        if(isPrinterPrinting()){
            setTemperatureTmpValue(newTemperature);
            setIsTemperatureDigitCodeVisible(true);
            setProfileIdTmpValue(farmProfileId);
        }
        else
            handleTemperatureChangeAfterDigitCode(null, newTemperature, farmProfileId);
    };

    const handleTemperatureChangeAfterDigitCode = (user=null, newTemperature=null, newFarmProfileId=null) => {
        const temperature = newTemperature !== null ? newTemperature : temperatureTmpValue;
        const farmProfileId = newFarmProfileId !== null ? newFarmProfileId : profileIdTmpValue;
        sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.TEMPERATURE, temperature);
        saveFarmEvent(FARM_EVENT_TYPES.TEMPERATURE, temperature, user !== null ? user.id : null, farmProfileId);

        // Disable running profile
        sendFarmCommandEvent(farmCell.system_code, 'profile_id', null);
    };

    const handleExtractionChange = (newExtraction) => {
        if(isPrinterPrinting()){
            setExtractionTmpValue(newExtraction);
            setIsExtractionDigitCodeVisible(true);
        }
        else
            handleExtractionChangeAfterDigitCode(null, newExtraction);
    };

    const handleExtractionChangeAfterDigitCode = (user=null, newExtraction=null) => {
        const extraction = newExtraction !== null ? newExtraction : extractionTmpValue;
        sendFarmCommandEvent(farmCell.system_code, SMART_FARM_COMMANDS.AIR_EXTRACTION, extraction);
        saveFarmEvent(FARM_EVENT_TYPES.AIR_EXTRACTION_COMMAND, extraction, user !== null ? user.id : null);
    };

    /* Get right command air extraction value to display (command sent value or influxdb value) */
    // TODO Replace by a data from influxdb + socket
    const getCommandAirExtractionToDisplay = useCallback(() => {
        return 0;
    }, []);

    /* Get maintenance data from smart farm data (date and if cell is in maintenance) */
    const getSmartFarmMaintenanceInfo = () => {
        if(smartFarmCellData && smartFarmCellData[SMART_FARM_DATA_FIELDS.MAINTENANCE_ACTIVE] !== undefined &&
            smartFarmCellData[SMART_FARM_DATA_FIELDS.MAINTENANCE_ACTIVE] !== null) {
            return {date: Math.floor(smartFarmCellData.time / 1000), isInMaintenance: smartFarmCellData[SMART_FARM_DATA_FIELDS.MAINTENANCE_ACTIVE] > 0};
        }
        return {date: null, isInMaintenance: null};
    }

    if(historyEvents.isLoading) return <Loading/>;
    if(historyEvents.isError) return <Error/>;

    return(
        <div className="farm-dialog-body">
            <DigitCode
                visible={isTemperatureDigitCodeVisible} setVisible={setIsTemperatureDigitCodeVisible}
                callback={handleTemperatureChangeAfterDigitCode} errorCallback={() => null}
            />
            <DigitCode
                visible={isExtractionDigitCodeVisible} setVisible={setIsExtractionDigitCodeVisible}
                callback={handleExtractionChangeAfterDigitCode} errorCallback={() => null}
            />

            {isUserGranted('ROLE_MANAGE_SMART_FARM') &&
                <EnvironmentManagerBlock
                    farmCell={farmCell} saveFarmEvent={saveFarmEvent} isPrinterPrinting={isPrinterPrinting}
                    farmProfiles={farmProfiles} systemType={farmCell.system_type} setAlert={setAlert}
                    getFarmProfiles={getFarmProfiles}
                    saveOpenDoor={saveOpenDoor}
                />
            }

            <OutlinedCard>
                {getChartsDataRangeBlock()}

                <hr/>

                {getTemperatureBlock(
                    historyEvents.data,
                    (farmCell.system_type === CELL_TYPES.PRINTER || farmCell.system_type === CELL_TYPES.MATERIALS),
                    structureMaintenance, cellMaintenance, handleTemperatureChange
                )}

                <hr/>

                {getHumidityBlock()}

                <hr/>

                {getPollutionBlock(
                    (farmCell.system_type === CELL_TYPES.PRINTER || farmCell.system_type === CELL_TYPES.POST_TREATMENT),
                    structureMaintenance, cellMaintenance, getCommandAirExtractionToDisplay(), handleExtractionChange, farmCell.filter_change,
                    farmCell
                )}
            </OutlinedCard>

            {farmCell.system_type === CELL_TYPES.MATERIALS &&
                <MaterialStatusBlock
                    farmCell={farmCell}
                    materialStatus={getMaterialCellStatus(smartFarmCellData)}
                    systemCode={farmCell.system_code}
                />
            }

            <HistoryEventsBlock historyEvents={historyEvents.data}/>

            {getMaintenanceBlock(structureMaintenance, cellMaintenance, farmStructureId, farmCell.id, getSmartFarmMaintenanceInfo(), farmCell)}

        </div>
    );
}