import {FarmCellPrinter} from "./FarmCellPrinter";
import {FarmCellMaterial} from "./FarmCellMaterial";
import {FarmCellPostTreatment} from "./FarmCellPostTreatment";
import {
    CELL_SIZES,
    CELL_TYPES,
    getTemperatureState,
    LED_COLORS,
    TEMPERATURE_STATES
} from "../../../services/FarmService";
import {useHistory, useParams} from "react-router-dom";
import {useEffect} from "react";

export const FarmCell = (props) => {

    const {farmCell, cellHeight, smartFarmCellData, handleClickOpenRightPart, setIsWeighModalOpen, isCellInMaintenance} = props;

    let {farmCellId} = useParams();
    let history = useHistory();

    useEffect(() => {
        if(farmCell.id === parseInt(farmCellId))
            handleClickOpenRightPart({target: null}, farmCell, smartFarmCellData);
        history.push('/');
    }, []);

    if(farmCell === undefined || farmCell === null || farmCell.system_type === undefined || farmCell.system_type === null) return null;

    const cellSizeType = cellHeight > CELL_SIZES.BIG ? CELL_SIZES.BIG : (cellHeight > CELL_SIZES.SMALL ? CELL_SIZES.SMALL : CELL_SIZES.XS);

    const isAirExtractionOn = () => {
        return smartFarmCellData && smartFarmCellData.air_extraction && smartFarmCellData.air_extraction > 0;
    }

    const getTemperatureManagementText = () => {
        const temperatureState = getTemperatureState(smartFarmCellData);
        if(temperatureState === TEMPERATURE_STATES.HEATING)
            return <>Heating environment <div className="led-bounce red"/></>;
        else if(temperatureState === TEMPERATURE_STATES.COOLING)
            return <>Cooling environment <div className="led-bounce blue"/></>;
        else
            return <>Optimal temperature <div className="led-inactive"/></>;
    };

    const getCellContentClasses = () => {
        return isCellInMaintenance ? 'cell-content cell-content-maintenance' : 'cell-content';
    }

    return(
        <div
            className="farm-cell" style={{height: cellHeight + '%'}}
            onClick={(event) => handleClickOpenRightPart(event, farmCell, smartFarmCellData)}
        >
            {(farmCell.system_type === CELL_TYPES.PRINTER || farmCell.system_type === CELL_TYPES.POST_TREATMENT) &&
                <>
                    <div className="farm-cell__states">
                        {getTemperatureManagementText()}
                    </div>
                    <div className="farm-cell__states">
                        Air extraction {isAirExtractionOn() ? <>active <div className="led-bounce blue"/></> : <>inactive <div className="led-inactive"/></>}
                    </div>
                </>
            }
            {/* Cell content by cell type */}
            {farmCell.system_type === CELL_TYPES.PRINTER &&
                <FarmCellPrinter
                    printers={farmCell.printers ? farmCell.printers : []} cellSize={cellSizeType} smartFarmCellData={smartFarmCellData}
                    cellContentClasses={getCellContentClasses()}
                />
            }
            {farmCell.system_type === CELL_TYPES.MATERIALS &&
                <FarmCellMaterial
                    cellSize={cellSizeType} setIsWeighModalOpen={setIsWeighModalOpen} smartFarmCellData={smartFarmCellData}
                    cellContentClasses={getCellContentClasses()} farmCell={farmCell}
                />
            }
            {farmCell.system_type === CELL_TYPES.POST_TREATMENT &&
                <FarmCellPostTreatment cellContentClasses={getCellContentClasses()}/>
            }
            {/* cell hover effect */}
            <div className="cell-content-hover">
                <div className="cell-content-hover-radius"></div>
            </div>
        </div>
    );
}
